import NextStep from "../Components/NextStep";
import arrow from "../images/Group 15.png";

export default function AddtoCart() {
    return (
        <div className="py-[30px] md:py-[60px] bg-[#0E1629] actors-sec relative">
            <div className="container m-auto p-5 relative z-10">
                <div className=" text-center">
                    <span className="text-[#fff] text-[30px] leading-[40px] font-medium">
                        Find the Average Cost for Your Job
                    </span>
                    <h2 className="text-[#fff] text-[45px] mt-[10px] leading-[55px] m-auto w-full font-bold text-center">
                        I am looking for a voice actor for my
                    </h2>
                    <NextStep />

                    <h5 className="font-bold text-white text-[40px] leading-[50px] mb-[20px]">
                        $215{" "}
                        <span className="text-[30px] leading-[40px] font-normal">
                            USD
                        </span>
                    </h5>

                    <span className="text-white text-[20px] leading-[28px] font-normal">Does this fit your budget?</span>
                    <button
                        type="button"
                        className="text-white flex gap-[15px] mt-[20px] m-auto items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                    >
                        Get Started Free
                        <img src={arrow} alt="icon" className="w-[30px]" />
                    </button>
                </div>
            </div>
        </div>
    );
}
