import Header from "../Components/header";
import Hello from "../Components/Slider";
import Work from "../Components/Work";
import Footer from "../Components/Footer";
import AdBanner from "../Components/AdBanner";
import Filters from "../Components/Filters";
import Brands from "../Components/Brands";
import ParentComponent from "../Components/ParentComponent";
import Talent from "../Components/Talent";
import Resources from "../Components/Resources";
import About from "../Components/About";
import Categories from "../Components/Categories";

const Online = () => {
  return (
    <div className="Online-Page">
      <Header />
      <Hello />
      <AdBanner />
      <Filters />
      <Brands />
      <ParentComponent />
      <Talent />
      <Resources />
      <About />
      <Categories />
      <Work />
      <Footer />
    </div>
  );
};

export default Online;
