import { useState } from "react";
import { useNavigate } from "react-router-dom";

export default function FindTalent() {
    const [activeButton, setActiveButton] = useState("Client");
 

    const navigate = useNavigate();

    const handleButtonClick = (button) => {
        setActiveButton(button);
        const url =
            button === "HowItWorks" ? "/HowItWorks/" : "/Talents";
            navigate(url);
    };

    return (
        <>
            <div className="active-btns flex justify-center py-[30px] md:py-[60px gap-[20px] bg-[#DBF6F3]">
                <button
                    onClick={() => handleButtonClick("HowItWorks")}
                    className={`py-3 px-[20px] rounded-md font-bold ${
                        activeButton === "HowItWorks"
                            ? "text-white bg-[#01bcac]"
                            : "text-[#01bcac] bg-white"
                    }`}
                >
                    Client
                </button>
                <button
                    onClick={() => handleButtonClick("Talents")}
                    className={`py-2 px-4 rounded-md font-bold ${
                        activeButton === "Talents"
                            ? "text-white bg-[#01bcac]"
                            : "text-[#01bcac] bg-white"
                    }`}
                >
                    Talent
                </button>
            </div>
        </>
    );
}
