import image from "../images/picture.png";
import arrow from "../images/Group 15.png";


export default function WorksTxt() {
    return (
        <section className="image-text-blk pb-0 md:py-[30px] py-[30px]">
            <div className="container m-auto p-5 pb-0">
                <div className="row grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 bg-[#F4F3F3] rounded-[15px] gap-[50px] items-center p-[50px]">
                    <div className="column">
                        <h2 className="text-[#0E1629] text-[45px] leading-[55px] w-full font-bold">
                            Post a Job (It's Free!)
                        </h2>
                        <p className="pt-3 text-lg font-normal lg:text-base text-[#5D5D5D]">
                            Have talent come to you. Receive custom auditions
                            from a variety of talent, within minutes after
                            posting a job.
                        </p>

                        <ul className="list-none styles-ul">
                            <li className="text-lg font-normal lg:text-base">
                                Post your free job and we automatically invite
                                the best voice actors to reply.
                            </li>

                            <li className="text-lg font-normal lg:text-base"> 
                                Compare auditions and quotes. Only pay once
                                you’ve selected the right voice actor for the
                                job.
                            </li>

                            <li className="text-lg font-normal lg:text-base">
                                Download your files directly from our secure
                                platform. Leave a review and you’re done!
                            </li>
                        </ul>

                        <button
                            type="button"
                            className="text-white flex gap-[15px] mt-[35px] mr-auto items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                        >
                            Post a Job Free
                            <img src={arrow} alt="icon" className="w-[30px]" />
                        </button>
                    </div>

                    <div className="column">
                        <img src={image} alt="img" className="w-full" />
                    </div>
                </div>
            </div>
        </section>
    );
}
