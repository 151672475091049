
export default function NextStep() {
    return (
        <>
            <div className="flex filter-count items-center text-white justify-center gap-[25px] my-[20px] flex-wrap">
                <button
                    id="dropdownDefaultButton"
                    data-dropdown-toggle="dropdown"
                    className="text-white border-0 bg-transparen font-medium rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                    type="button"
                >
                    Dropdown button{" "}
                    <svg
                        className="w-2.5 h-2.5 ms-3"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 10 6"
                    >
                        <path
                            stroke="currentColor"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth="2"
                            d="m1 1 4 4 4-4"
                        />
                    </svg>
                </button>
                <div
                    id="dropdown"
                    className="z-10 hidden bg-transparent rounded-[10px] w-44 "
                >
                    <ul
                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownDefaultButton"
                    >
                        <li>
                            <a
                                href="#"
                                className="block px-4 py-2 hover:bg-gray-100 dark:hover:text-white"
                            >
                                Dashboard
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                className="block px-4 py-2 hover:bg-gray-100 dark:hover:text-white"
                            >
                                Settings
                            </a>
                        </li>
                        <li>
                            <a
                                href="#"
                                className="block px-4 py-2 hover:bg-gray-100 dark:hover:text-white"
                            >
                                Earnings
                            </a>
                        </li>
                    </ul>
                </div>
                that is
                <form className="flex items-center gap-[10px]">
                    <label
                        htmlFor="quantity-input"
                        className="block text-sm font-medium text-white"
                    >
                        Choose quantity:
                    </label>
                    <div className="relative border-outside flex items-center max-w-[8rem]">
                        <button
                            type="button"
                            id="decrement-button"
                            data-input-counter-decrement="quantity-input"
                            className="bg-transparent text-white border-0  rounded-s-lg p-3 h-11"
                        >
                            <svg
                                className="w-3 h-3  dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 18 2"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M1 1h16"
                                />
                            </svg>
                        </button>
                        <input
                            type="text"
                            id="quantity-input"
                            data-input-counter
                            aria-describedby="helper-text-explanation"
                            className="bg-transparent h-11 border-0  text-center text-sm block w-full py-2.5 "
                            placeholder="999"
                            required
                        />
                        <button
                            type="button"
                            id="increment-button"
                            data-input-counter-increment="quantity-input"
                            className="bg-transparent border-0 rounded-e-lg p-3 h-11"
                        >
                            <svg
                                className="w-3 h-3 dark:text-white"
                                aria-hidden="true"
                                xmlns="http://www.w3.org/2000/svg"
                                fill="none"
                                viewBox="0 0 18 18"
                            >
                                <path
                                    stroke="currentColor"
                                    strokeLinecap="round"
                                    strokeLinejoin="round"
                                    strokeWidth="2"
                                    d="M9 1v16M1 9h16"
                                />
                            </svg>
                        </button>
                    </div>
                    <span
                        id="helper-text-explanation"
                        className="text-sm text-white"
                    >
                        minute(s) in length
                    </span>
                </form>
            </div>
        </>
    );
}
