import arrow from "../images/Group 15.png";
import MusicBoard from "../Components/MusicBoard";

const TabContent = () => {
  return (
    <>
    <div>
      <h2 className="text-[#0E1629] text-[45px] leading-[55px] m-auto w-full font-bold">
      Top Voices for Online Ads
      </h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[25px] pt-[25px]">
        <MusicBoard />
      </div>

      <div className="text-center pt-[50px]">
        <button
          type="button"
          className="text-[#01BCAC] border border-solid border-[#01BCAC] tab-btn flex gap-[15px] items-center m-auto bg-transparent  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
        >
           See All
          <img src={arrow} alt="icon" className="w-[30px]" />
        </button>
      </div>
    </div>

    <div>
      <h2 className="text-[#0E1629] text-[45px] leading-[55px] m-auto w-full font-bold">
      Top Voices for Video Narration
      </h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[25px] pt-[25px]">
        <MusicBoard />
      </div>

      <div className="text-center pt-[50px]">
        <button
          type="button"
          className="text-[#01BCAC] border border-solid border-[#01BCAC] tab-btn flex gap-[15px] items-center m-auto bg-transparent  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
        >
           See All
          <img src={arrow} alt="icon" className="w-[30px]" />
        </button>
      </div>
    </div>


    <div>
      <h2 className="text-[#0E1629] text-[45px] leading-[55px] m-auto w-full font-bold">
      Top Voices for E Learning
      </h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[25px] pt-[25px]">
        <MusicBoard />
      </div>

      <div className="text-center pt-[50px]">
        <button
          type="button"
          className="text-[#01BCAC] border border-solid border-[#01BCAC] tab-btn flex gap-[15px] items-center m-auto bg-transparent  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
        >
           See All
          <img src={arrow} alt="icon" className="w-[30px]" />
        </button>
      </div>
    </div>

    <div>
      <h2 className="text-[#0E1629] text-[45px] leading-[55px] m-auto w-full font-bold">
      Top Voices for E Learning
      </h2>
      <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-[25px] pt-[25px]">
        <MusicBoard />
      </div>

      <div className="text-center pt-[50px]">
        <button
          type="button"
          className="text-[#01BCAC] border border-solid border-[#01BCAC] tab-btn flex gap-[15px] items-center m-auto bg-transparent  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
        >
           See All
          <img src={arrow} alt="icon" className="w-[30px]" />
        </button>
      </div>
    </div>
    </>


    
  );
};

export default TabContent;
