import React, { forwardRef, useImperativeHandle, useRef, useState } from "react";
import { FaCirclePlay, FaCirclePause } from "react-icons/fa6";

const SongsSample = forwardRef(({ audioSrc, audioRefs, index }, ref) => {
  const audioRef = useRef(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const [currentTime, setCurrentTime] = useState(0);
  const [duration, setDuration] = useState(0);

  useImperativeHandle(ref, () => ({
    stop() {
      if (audioRef.current) {
        audioRef.current.pause();
        audioRef.current.currentTime = 0;
        setCurrentTime(0);
        setIsPlaying(false);
      }
    },
  }), []);

  const togglePlay = () => {
    if (audioRefs.current && Array.isArray(audioRefs.current)) {
      audioRefs.current.forEach((audio, key) => {
        if (index !== key && audio && audio.stop) {
          audio.stop();
        }
      });
    }

    if (isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play().catch((error) => {
        console.error("Error playing audio:", error);
      });
    }
    setIsPlaying(!isPlaying);
  };

  const handleTimeUpdate = () => {
    setCurrentTime(audioRef.current.currentTime);
  };

  const handleDurationChange = () => {
    setDuration(audioRef.current.duration);
  };

  const formatTime = (time) => {
    const minutes = Math.floor(time / 60);
    const seconds = Math.floor(time % 60);
    return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
  };

  return (
    <div className="play-container p-[20px] rounded-[15px]">
      <div className="flex">
        <audio
          ref={audioRef}
          onTimeUpdate={handleTimeUpdate}
          onDurationChange={handleDurationChange}
        >
          <source src={audioSrc} type="audio/mpeg" />
          Your browser does not support the audio element.
        </audio>
        <button onClick={togglePlay}>
          {isPlaying ? <FaCirclePause /> : <FaCirclePlay />}
        </button>
        <div className="songname">
          <p>Compassionate Demo - Warm, Aged</p>
          <span>Video Narration</span>
        </div>
        <span className="ml-[20px] text-[#01BCAC] text-[14px] leading-[25px] font-medium inline-block">
          {formatTime(currentTime)}
        </span>
      </div>
    </div>
  );
});

export default SongsSample;
