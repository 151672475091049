import songs from "../audio/Naina-Kbps.mp3";
import verified from "../images/Verified.png";
import { FaStar } from "react-icons/fa";
import image from "../images/Ellipse 7.png";
import image2 from "../images/Ellipse 10.png";
import image3 from "../images/Ellipse 9.png";
import PlayContainer from "../Components/Audio";
import React, { useRef } from "react";

const audios = [songs];  // Assuming you have at least one song in the array

export default function MusicBoard() {
  const audioRefs = useRef([]);

  return (
    <>
      <div className="card-block p-[20px] bg-white rounded-[15px]">
        <div className="flex items-center">
          <img
            src={image2}
            alt="icon"
            className="max-w-[70px] w-full rounded-full"
          />
          {audios.map((audio, key) => (
            <PlayContainer
              ref={(el) => (audioRefs.current[key] = el)}
              key={key}
              audioSrc={audio}
              audioRefs={audioRefs}
              index={key}
            />
          ))}
        </div>
        <h6 className="flex items-center gap-[8px] font-bold text-[#0E1629] text-[22px] leading-[30px] my-[10px]">
          Miriam Olsan
          <img src={verified} alt="icon" className="w-[20px]" />
        </h6>
        <h6 className="text-[#0E1629] text-[18px] leading-[25px] font-semibold">
          Miriam Olson - Quirky, Fun, Heartfelt, Conversational, Friendly
        </h6>

        <p className="text-[#0E1629] text-[16px] leading-[23px] font-light py-[15px]">
          Television Ad • English • Young Adult (18-35) • Narrator •
          Storyteller • Student • Teacher • Business Woman • Amusing...
        </p>

        <div className="rating grid grid-cols-3 gap-[10px] items-center">
          <div className="stars justify-center gap-[5px] flex items-center bg-[#DBF6F3] p-[5px] rounded-[5px]">
            <FaStar />
            <span className="text-[#0E1629] text-[14px] leading-[25px] font-medium">
              (5.0)
            </span>
          </div>

          <div className="total-reviews text-center text-[#0E1629] text-[13px] leading-[25px] font-medium">
            (234 Reviews)
          </div>

          <button
            type="button"
            className="text-white bg-[#01BCAC] font-medium text-[13px] rounded-lg focus:outline-none"
          >
            Find Talent
          </button>
        </div>
      </div>

      {/* Repeat similar card blocks for other entries if needed */}
      <div className="card-block p-[20px] bg-white rounded-[15px]">
        <div className="flex items-center">
          <img
            src={image}
            alt="icon"
            className="max-w-[70px] w-full rounded-full"
          />

          <PlayContainer
            ref={(el) => (audioRefs.current[1] = el)}
            audioSrc={songs}
            audioRefs={audioRefs}
            index={1}
          />
        </div>
        <h6 className="flex items-center gap-[8px] font-bold text-[#0E1629] text-[22px] leading-[30px] my-[10px]">
          Miriam Olsan
          <img src={verified} alt="icon" className="w-[20px]" />
        </h6>
        <h6 className="text-[#0E1629] text-[18px] leading-[25px] font-semibold">
          Miriam Olson - Quirky, Fun, Heartfelt, Conversational, Friendly
        </h6>

        <p className="text-[#0E1629] text-[16px] leading-[23px] font-light py-[15px]">
          Television Ad • English • Young Adult (18-35) • Narrator •
          Storyteller • Student • Teacher • Business Woman • Amusing...
        </p>

        <div className="rating grid grid-cols-3 gap-[10px] items-center">
          <div className="stars justify-center gap-[5px] flex items-center bg-[#DBF6F3] p-[5px] rounded-[5px]">
            <FaStar />
            <span className="text-[#0E1629] text-[14px] leading-[25px] font-medium">
              (5.0)
            </span>
          </div>

          <div className="total-reviews text-center text-[#0E1629] text-[13px] leading-[25px] font-medium">
            (234 Reviews)
          </div>

          <button
            type="button"
            className="text-white bg-[#01BCAC] font-medium text-[13px] rounded-lg focus:outline-none"
          >
            Find Talent
          </button>
        </div>
      </div>

      <div className="card-block p-[20px] bg-white rounded-[15px]">
        <div className="flex items-center">
          <img
            src={image3}
            alt="icon"
            className="max-w-[70px] w-full rounded-full"
          />

          <PlayContainer
            ref={(el) => (audioRefs.current[2] = el)}
            audioSrc={songs}
            audioRefs={audioRefs}
            index={2}
          />
        </div>
        <h6 className="flex items-center gap-[8px] font-bold text-[#0E1629] text-[22px] leading-[30px] my-[10px]">
          Miriam Olsan
          <img src={verified} alt="icon" className="w-[20px]" />
        </h6>
        <h6 className="text-[#0E1629] text-[18px] leading-[25px] font-semibold">
          Miriam Olson - Quirky, Fun, Heartfelt, Conversational, Friendly
        </h6>

        <p className="text-[#0E1629] text-[16px] leading-[23px] font-light py-[15px]">
          Television Ad • English • Young Adult (18-35) • Narrator •
          Storyteller • Student • Teacher • Business Woman • Amusing...
        </p>

        <div className="rating grid grid-cols-3 gap-[10px] items-center">
          <div className="stars justify-center gap-[5px] flex items-center bg-[#DBF6F3] p-[5px] rounded-[5px]">
            <FaStar />
            <span className="text-[#0E1629] text-[14px] leading-[25px] font-medium">
              (5.0)
            </span>
          </div>

          <div className="total-reviews text-center text-[#0E1629] text-[13px] leading-[25px] font-medium">
            (234 Reviews)
          </div>

          <button
            type="button"
            className="text-white bg-[#01BCAC] font-medium text-[13px] rounded-lg focus:outline-none"
          >
            Find Talent
          </button>
        </div>
      </div>
    </>
  );
}
