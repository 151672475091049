import image from "../images/mic.png";
export default function LetVoices() {
  return (
    <section className="let-section pb-[30px] pt-[30px]">
      <div className="container m-auto p-5">
        <h2 className="text-[#0E1629] text-[45px] mb-[15px] leading-[55px] m-auto w-full font-bold text-center">
          Need a Voice Actor? Let Voices Help.
        </h2>
        <div className="row grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 mt-[35px] gap-[25px]">
          <div className="colums">
            <img src={image} alt="img" className="w-full max-w-[70px]" />
            <h5 className="text-[24px] leading-[35px] font-semibold text-[#000]">
              Professional Voice Actors, On‑Demand
            </h5>
            <p className="text-[#5D5D5D] text-[17px] leading-[23px] font-normal text-left">
              Look beyond your local or existing networks and tap into our
              global community of professional freelance voice actors, whenever
              you need. With thousands of trusted voice actors at your
              fingertips, you have the flexibility to scale your creative teams
              on your terms, project‑by‑project.
            </p>
          </div>

          <div className="colums">
            <img src={image} alt="img" className="w-full max-w-[70px]" />
            <h5 className="text-[24px] leading-[35px] font-semibold text-[#000]">
              Quality Voice Audio that Exceeds Expectations
            </h5>
            <p className="text-[#5D5D5D] text-[17px] leading-[23px] font-normal text-left">
              Impress your audiences with award‑worthy audio quality from
              professional freelance voice actors. Once you’ve posted your job,
              you’ll receive custom responses from talent we’ve either matched
              to your job, or you’ve invited personally, showing you the quality
              you’re getting before you make a hiring decision.
            </p>
          </div>

          <div className="colums">
            <img src={image} alt="img" className="w-full max-w-[70px]" />
            <h5 className="text-[24px] leading-[35px] font-semibold text-[#000]">
              Stay On Budget with Total Budget Control
            </h5>
            <p className="text-[#5D5D5D] text-[17px] leading-[23px] font-normal text-left">
              Post your job, receive custom responses from professional
              freelance voice actors, all for free. When posting, you can select
              a fixed budget or a range that you’d like talent to quote within,
              and you only pay when you hire. Your payment is held safely via
              SurePay™ until the job is done and you’re satisfied.
            </p>
          </div>

          <div className="colums">
            <img src={image} alt="img" className="w-full max-w-[70px]" />
            <h5 className="text-[24px] leading-[35px] font-semibold text-[#000]">
              Never Miss a Deadline Again
            </h5>
            <p className="text-[#5D5D5D] text-[17px] leading-[23px] font-normal text-left">
              Turn your creative projects around in less time than ever. Our
              easy‑to‑use and purpose‑built marketplace makes posting a job and
              receiving custom auditions happen in a manner of minutes. Make
              same‑day or next‑day hiring decisions and receive your final files
              within hours.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
