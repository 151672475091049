export default function PrimaryButton({ className = '', disabled, children, ...props }) {
    return (
        <button
            {...props}
            className={
                `inline-flex ms-0 text-[18px] items-center px-4 py-3 justify-center bg-[#01BCAC] border border-transparent rounded-md font-semibold capitalize w-full  text-white tracking-widest focus:outline-none transition ease-in-out duration-150 ${
                    disabled && 'opacity-25'
                } ` + className
            }
            disabled={disabled}
        >
            {children}
        </button>
    );
}
