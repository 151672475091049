// Voices.js

import React, { useRef } from "react";
import songs from "../audio/Naina-Kbps.mp3";
import images from "../images/Group 114.png";
import arrow from "../images/Group 15.png";
import PlayContainer from "./Audio";

const audios = Array(4).fill(songs);

export default function Voices() {
    const audioRefs = useRef([]);
    return (
        <section className="py-[30px] md:py-[60px] voice-sec">
            <div className="container m-auto p-5">
                <div className="row">
                    <h2 className="text-[#0E1629] text-[45px] leading-[55px] font-bold pb-[30px]">
                        Find any Voice for Your Next Project
                    </h2>
                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-[2rem] md:gap-[5rem] pt-[25px]">
                        <div className="inside-col flex flex-col gap-[20px]">
                            {audios.map((audio, key) => (
                                <PlayContainer
                                    ref={(el) => (audioRefs.current[key] = el)}
                                    key={key}
                                    audioSrc={audio}
                                    audioRefs={audioRefs}
                                    index={key}
                                />
                            ))}
                            <div className="mt-[20px]">
                                <button
                                    type="button"
                                    className="text-white flex gap-[15px] items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-left focus:outline-none"
                                >
                                    Browse All Over Categories
                                    <img
                                        src={arrow}
                                        alt="icon"
                                        className="w-[30px]"
                                    />
                                </button>
                            </div>
                        </div>
                        <div className="image-block">
                            <img src={images} alt="img" className="w-full" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
