import { Checkbox } from "@material-tailwind/react";
import arrow from "../images/Group 15.png";

const SubscribePlans = () => {
    return (
        <section className="pb-[30px] md:pb-[60px] pt-0">
            <div className="container m-auto p-5 pb-0">
                <h2 className="text-[#0E1629] text-[45px] max-w-[700px] m-auto leading-[55px] w-full font-bold text-center">
                    Compare Memberships
                </h2>
                <div className="">
                    <table className="w-full m-auto table-fixed mt-[30px]">
                        <tbody>
                            <tr>
                                <td className="rounded-[5px] p-[15px] w-[50%] align-top">
                                    <table className="w-full m-auto inside-table">
                                        <thead>
                                            <tr>
                                                <th className="font-bold text-left text-[25px] leading-[33px]">
                                                    Membership Benefits
                                                </th>
                                            </tr>
                                            <tr>
                                                <td className="text-[20px] leading-[28px] font-semibold">
                                                    Being Discovered
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Create a profile</td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Get highest priority search
                                                    rankings
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    Have your Talent Profile
                                                    Card featured on website
                                                </td>
                                            </tr>
                                            
                                        </thead>
                                    </table>
                                </td>

                                <td className="bg-[#DBF6F3] rounded-[5px] p-[15px] text-center w-[24%]">
                                    <table className="w-full m-auto">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <button className="bg-[#01bcac] rounded-[5px] text-white py-[10px] px-[20px] w-full font-bold text-[20px] leading-[25px]">
                                                        Guest
                                                    </button>
                                                </th>
                                            </tr>

                                            <tr>
                                                <th>
                                                    <Checkbox
                                                        color="blue"
                                                        className="invisible"
                                                        defaultChecked
                                                    />
                                                </th>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Checkbox
                                                        color="blue"
                                                        defaultChecked
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Checkbox
                                                        color="blue"
                                                        defaultChecked
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Checkbox
                                                        color="blue"
                                                        defaultChecked
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <button
                                                        type="button"
                                                        className="text-white flex gap-[15px] m-auto items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                                                    >
                                                        Sign Up Free
                                                        <img
                                                            src={arrow}
                                                            alt="icon"
                                                            className="w-[30px]"
                                                        />
                                                    </button>
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>
                                </td>

                                <td className="w-[2px]"></td>

                                <td className="bg-[#DBF6F3] rounded-[5px] p-[15px] text-center w-[24%]">
                                    <table className="w-full m-auto">
                                        <thead>
                                            <tr>
                                                <th>
                                                    <button className="bg-[#01bcac] rounded-[5px] text-white py-[10px] px-[20px] w-full font-bold text-[20px] leading-[25px]">
                                                        Premium
                                                    </button>
                                                </th>
                                            </tr>
                                            <tr>
                                                <th>
                                                    <Checkbox
                                                        color="blue"
                                                        className="invisible"
                                                        defaultChecked
                                                    />
                                                </th>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Checkbox
                                                        color="blue"
                                                        defaultChecked
                                                    />
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>
                                                    <Checkbox
                                                        color="blue"
                                                        defaultChecked
                                                    />
                                                </td>
                                            </tr>

                                            <tr>
                                                <td>
                                                    <Checkbox
                                                        color="blue"
                                                        defaultChecked
                                                    />
                                                </td>
                                            </tr>
                                            
                                            <tr>
                                                <td>
                                                    <button
                                                        type="button"
                                                        class="text-white flex gap-[15px] m-auto items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                                                    >
                                                        Buy Now
                                                        <img
                                                            src={arrow}
                                                            alt="icon"
                                                            className="w-[30px]"
                                                        />
                                                    </button>
                                                </td>
                                            </tr>
                                        </thead>
                                    </table>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </section>
    );
};
export default SubscribePlans;
