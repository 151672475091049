import image from "../images/users.png";
import image2 from "../images/suitcase.png";
import arrow from "../images/Group 15.png";
import image3 from "../images/rupee.png";

export default function EarnBoxes() {
    return (
        <section className="boxes-section py-[30px] md:py-[60px]">
            <div className="container m-auto p-5">
                <h2 className="text-[#0E1629] text-[45px] mb-[15px] leading-[55px] m-auto w-full font-bold text-center">
                    Start Earning Today
                </h2>
                <p className="text-center mb-[35px] text-lg font-medium lg:text-base text-[#1B253D]">
                    Creating a search-optimized profile and uploading unlimited
                    demos and portfolio samples is always free.
                </p>
                <div className="row grid grid-cols-1 sm:grid-cols-2 mt-[25px] gap-[30px] lg:grid-cols-3">
                    <div className="col p-[25px] bg-[#DBF6F3] rounded-[15px] h-full flex flex-col gap-[15px]">
                        <img src={image} alt="img" className="w-[80px]" />
                        <h5 className="text-[24px] leading-[35px] font-bold text-[#0E1629]">
                            Create Your Profile, Get Invited
                        </h5>
                        <span className="text-[#0E1629]  block font-semibold text-[17px] leading-[23px]">
                            Free and Paid Options
                        </span>
                        <p className="text-[#0E1629] text-[17px] leading-[23px] font-normal text-left">
                            Upload demos and portfolio samples to demonstrate
                            your work. With a Guest membership, you can be
                            privately invited to jobs. Upgrade to a Premium
                            membership to be automatically matched to jobs that
                            suit your skills.
                        </p>
                    </div>

                    <div className="col p-[25px] bg-[#DBF6F3] rounded-[15px] h-full flex flex-col gap-[15px]">
                        <img src={image2} alt="img" className="w-[80px]" />
                        <h5 className="text-[24px] leading-[35px] font-bold text-[#0E1629]">
                            Respond to Jobs, Create Packages
                        </h5>
                        <span className="text-[#0E1629]  block font-semibold text-[17px] leading-[23px]">
                            Work the Way You Want To
                        </span>
                        <p className="text-[#0E1629] text-[17px] leading-[23px] font-normal text-left">
                            Upload demos and portfolio samples to demonstrate
                            your work. With a Guest membership, you can be
                            privately invited to jobs. Upgrade to a Premium
                            membership to be automatically matched to jobs that
                            suit your skills.
                        </p>
                    </div>

                    <div className="col p-[25px] bg-[#DBF6F3] rounded-[15px] h-full flex flex-col gap-[15px]">
                        <img src={image3} alt="img" className="w-[80px]" />
                        <h5 className="text-[24px] leading-[35px] font-bold text-[#0E1629]">
                            Get Hired, Get Paid
                        </h5>
                        <span className="text-[#0E1629] block font-semibold text-[17px] leading-[23px]">
                            Worry and Hassle-Free Payment
                        </span>
                        <p className="text-[#0E1629] text-[17px] leading-[23px] font-normal text-left">
                            Upload demos and portfolio samples to demonstrate
                            your work. With a Guest membership, you can be
                            privately invited to jobs. Upgrade to a Premium
                            membership to be automatically matched to jobs that
                            suit your skills.
                        </p>
                    </div>
                </div>
                <div className="text-center pt-[50px]">
                    <button
                        type="button"
                        className="text-white flex gap-[15px] items-center m-auto bg-[#0E1629]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                    >
                        See Talent Memberships
                        <img src={arrow} alt="icon" className="w-[30px]" />
                    </button>
                </div>
            </div>
        </section>
    );
}
