import Checkboxes from "./Checkboxes";
import Languages from "./Languages";
import Gender from "./Gender";
import arrow from "../images/Group 15.png";

export default function SelectOption() {
  return (
    <section className="py-[30px] md:py-[60px] bg-[#0E1629] option-sec relative">
      <div className="container m-auto p-5 relative z-10">
        <h2 className="text-white text-[45px] leading-[55px] m-auto w-full font-bold">
          Talent Finder
        </h2>
        <p className="pt-2 text-lg font-medium m-auto w-full lg:text-base text-[#EDEDED]">
          Not sure where to get started? Let us help you find the right voice to
          tell your story! We just need a few details.
        </p>

        <h6 className="text-[#01BCAC] text-[19px] leading-[25px] font-bold my-[18px]">
          First, what type of project do you have?
        </h6>
        <h6 className="text-[#fff] text-lg leading-[25px] font-normal">
          I am looking to find a voice talent for{" "}
          <b>(select all that applies)</b>
        </h6>

        <Checkboxes />

        <h6 className="text-[#01BCAC] text-[19px] leading-[25px] font-bold my-[18px]">
          Alright, let's hear more.
        </h6>
        <h6 className="text-[#fff] text-lg leading-[25px] font-normal">
          I am looking to find a voice talent for{" "}
          <b>(select all that applies)</b>
        </h6>

        <Languages />

        <h6 className="text-[#01BCAC] text-[19px] leading-[25px] font-bold my-[18px]">
          Lastly, what should the talent sound like?
        </h6>
        <h6 className="text-[#fff] text-lg leading-[25px] font-normal">
          I am looking to find a voice talent for{" "}
          <b>(select all that applies)</b>
        </h6>
        <Gender />

        <button
          type="button"
          className="text-white flex gap-[15px] items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
        >
          Find Talent
          <img src={arrow} alt="icon" className="w-[30px]" />
        </button>
      </div>
    </section>
  );
}
