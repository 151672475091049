import image from "../images/mic.png";
import image1 from "../images/lines.png";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

export default function Carrer() {
    return (
        <section className="let-section pb-[30px] pt-[30px]">
            <div className="container m-auto p-5">
                <h2 className="text-[#0E1629] text-[45px] mb-[15px] leading-[55px] m-auto w-full font-bold text-center">
                    Build Your Career
                </h2>
                <div className="">
                    <img src={image1} alt="img" className="w-full" />
                </div>
                <div className="row grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 mt-[35px] gap-[25px]">
                    <div className="colums">
                        <img
                            src={image}
                            alt="img"
                            className="w-full max-w-[70px]"
                        />
                        <h5 className="text-[24px] leading-[35px] font-semibold text-[#000]">
                            Voices Blogs
                        </h5>
                        <p className="text-[#5D5D5D] text-[17px] leading-[23px] font-normal text-left">
                            Articles on topics to help you hone your craft and
                            build your business.
                        </p>
                        <div className="button-col mt-[20px]">
                            <a
                                href="#"
                                className="text-[#00B5A5] text-[18px] font-bold flex items-center gap-1"
                            >
                                Visit the Blog
                                <MdKeyboardDoubleArrowRight />
                            </a>
                        </div>
                    </div>

                    <div className="colums">
                        <img
                            src={image}
                            alt="img"
                            className="w-full max-w-[70px]"
                        />
                        <h5 className="text-[24px] leading-[35px] font-semibold text-[#000]">
                            Webinars
                        </h5>
                        <p className="text-[#5D5D5D] text-[17px] leading-[23px] font-normal text-left">
                            Regularly scheduled interactive sessions with the
                            Voices team.
                        </p>

                        <div className="button-col mt-[20px]">
                            <a
                                href="#"
                                className="text-[#00B5A5] text-[18px] font-bold flex items-center gap-1"
                            >
                                Register Now
                                <MdKeyboardDoubleArrowRight />
                            </a>
                        </div>
                    </div>

                    <div className="colums">
                        <img
                            src={image}
                            alt="img"
                            className="w-full max-w-[70px]"
                        />
                        <h5 className="text-[24px] leading-[35px] font-semibold text-[#000]">
                            Voices Resources Library
                        </h5>
                        <p className="text-[#5D5D5D] text-[17px] leading-[23px] font-normal text-left">
                            Everything you need from tools to trends to
                            templates.
                        </p>

                        <div className="button-col mt-[20px]">
                            <a
                                href="#"
                                className="text-[#00B5A5] text-[18px] font-bold flex items-center gap-1"
                            >
                                Explore the Resource Library
                                <MdKeyboardDoubleArrowRight />
                            </a>
                        </div>
                    </div>

                    <div className="colums">
                        <img
                            src={image}
                            alt="img"
                            className="w-full max-w-[70px]"
                        />
                        <h5 className="text-[24px] leading-[35px] font-semibold text-[#000]">
                            Voices YouTube Channel
                        </h5>
                        <p className="text-[#5D5D5D] text-[17px] leading-[23px] font-normal text-left">
                            A treasure trove of content from technical tutorials
                            to office hours with the Voices CEO.
                        </p>

                        <div className="button-col mt-[20px]">
                            <a
                                href="#"
                                className="text-[#00B5A5] text-[18px] font-bold flex items-center gap-1"
                            >
                                Watch Now
                                <MdKeyboardDoubleArrowRight />
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
}
