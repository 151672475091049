
export default function GuideBanner() {
    return (
        <div className="py-[30px] md:py-[60px] bg-[#0E1629] actors-sec relative">
            <div className="container m-auto p-5 relative z-10">
                <div className="">
                    <h2 className="text-[#fff] max-w-[800px] text-[45px] leading-[55px] m-auto w-full font-bold text-center">
                        The Complete Guide to Voices for Talent
                    </h2>
                </div>
            </div>
        </div>
    );
}
