import React from 'react'
import Header from "../Components/header";
import Hello from "../Components/Slider";
import NextBanner from "../Components/NextBanner";
import SelectOption from "../Components/SelectOption";
import MainTabs from "../Components/MainTabs";
import Brands from "../Components/Brands";
import Filter from '../Components/Filter';
import LetVoices from '../Components/LetVoices';
import Services from "../Components/Services";
import Resources from '../Components/Resources';
import Work from "../Components/Work";
import Footer from "../Components/Footer";
import Actors from '../Components/Actors';
import ParentComponent from '../Components/ParentComponent';

function Talent() {
  return (
    <div className='Voices-Page'>
        <Header />
        <Hello />
        <NextBanner />
        <SelectOption />
        <MainTabs />
        <Filter />
        <LetVoices />
        <Brands />
        <Services />
        <Resources />
        <Actors />
        <ParentComponent />
        <Work />
        <Footer />
    </div>
  )
}

export default Talent