
export default function ContentList() {
    return (
        <section className="py-[30px] md:py-[60px]">
            <div className="container m-auto p-5">
                <h2 className="text-[#0E1629] text-[45px] mb-[15px] text-center leading-[55px] m-auto w-full font-bold">
                    Table of Contents
                </h2>
                <div className="main-row row grid grid-cols-1 sm:grid-cols-1 lg:grid-cols-2 p-[35px] bg-[#DBF6F3] rounded-[15px]">
                    <ul className="list-none">
                        <li><span>01</span>Joining Voices</li>
                        <li><span>02</span>Completing Your Profile</li>
                        <li><span>03</span>Replying To Jobs</li>
                        <li><span>04</span>Creating Packages</li>
                        <li><span>05</span>Getting Paid</li>
                    </ul>

                    <ul className="list-none">
                        <li><span>06</span>Communicating With Clients</li>
                        <li><span>07</span>Tweaking Your Settings</li>
                        <li><span>08</span>Mobile App</li>
                        <li><span>09</span>Trust Center</li>
                        <li><span>10</span>Resources and Customer Support</li>
                    </ul>
                </div>
            </div>
        </section>
    );
}
