export default function WorkBanner() {
    return (
        <div className="py-[30px] md:py-[60px] bg-[#0E1629] actors-sec relative">
            <div className="container m-auto p-5 relative z-10">
                <div className="">
                    <h2 className="text-[#fff] text-[45px] leading-[55px] m-auto w-full font-bold text-center">
                        Getting Paid
                    </h2>
                    <p className="py-[30px] pt-3 text-center text-lg max-w-[900px] font-medium m-auto w-full lg:text-base text-[#fff]">
                        Sign Up, Post a Job and Have Custom Auditions Sent to
                        You In MinutesGetting Paid
                    </p>
                </div>
            </div>
        </div>
    );
}
