import React, { useState } from "react";

const Tabs = ({ tabs }) => {
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  return (
    <div className="flex flex-wrap">
      <div className="flex gap-[15px]">
        {tabs.map((tab) => (
          <button
            key={tab.id}
            onClick={() => setActiveTab(tab.id)}
            className={`${
              activeTab === tab.id ? "bg-active" : ""
            }  no-active font-bold text-[17px] leading-[23px] text-[#01BCAC]`}
          >
            {tab.name}
          </button>
        ))}
      </div>
      <div className="pt-[60px] w-full tabs-section">
        {tabs.map((tab) => (
          <div
            key={tab.id}
            className={activeTab === tab.id ? "block" : "hidden"}
          >
            <div className="flex flex-col gap-[60px]">{tab.component}</div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Tabs;
