import Header from "../Components/header";
import Slider from "../Components/Slider";
import ProfileDetails from "../Components/ProfileDetails";
import Work from "../Components/Work";
import Footer from "../Components/Footer";

export default function SingleProfile() {
    return (
        <div className="single-profile">
            <Header />
            <Slider />
            <ProfileDetails />
            <Work />
            <Footer />
        </div>
    );
}
