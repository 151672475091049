import image from "../images/Group 45.png";
import image2 from "../images/Group 109.png";
import image3 from "../images/Group 108.png";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

export default function Services() {
  return (
    <>
      <section className= "relative services-banner py-[30px] md:py-[60px]">
        <div className="container relative m-auto p-5 z-10">
          <div className="row">
            <h2 className="text-white text-[45px] max-w-[1000px] leading-[55px] m-auto w-full font-bold text-center">
              Find Talent, Your Way
            </h2>
            <p className="pt-3 text-center text-lg lg:text-base text-[#EDEDED]">Three easy ways to find the right voice actor for your creative project.</p>
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 pt-[25px] gap-[25px]">
              <div className="colums text-center px-[15px]">
                <img src={image} alt="icon" className="w-full max-w-[150px]" />
                <h6 className="text-[#FFFFFF] font-semibold text-[23px] mt-[15px] leading-[35px]">
                  Post a Job
                </h6>
                <p className="pt-[15px] text-lg lg:text-left lg:text-base text-[#EDEDED]">
                  Post your job for free in minutes and let VoiceMatch™ invite
                  the best voice actors suited for your project. Receive
                  high-quality auditions, compare quotes and hire.
                </p>

                <a
                  href="#"
                  className="text-[#00B5A5] text-[18px] mt-[15px] justify-center font-bold flex items-center gap-1"
                >
                  Post a Job
                  <MdKeyboardDoubleArrowRight />
                </a>
              </div>

              <div className="colums text-center px-[15px]">
                <img src={image2} alt="icon" className="w-full max-w-[150px]" />
                <h6 className="text-[#FFFFFF] font-semibold text-[23px] mt-[15px] leading-[35px]">
                  Browse & Buy
                </h6>
                <p className="pt-[15px] text-lg lg:pt-5 lg:text-left lg:text-base text-[#EDEDED]">
                  Post your job for free in minutes and let VoiceMatch™ invite
                  the best voice actors suited for your project. Receive
                  high-quality auditions, compare quotes and hire.
                </p>

                <a
                  href="#"
                  className="text-[#00B5A5] text-[18px] mt-[15px] justify-center font-bold flex items-center gap-1"
                >
                  Browse Packages
                  <MdKeyboardDoubleArrowRight />
                </a>
              </div>

              <div className="colums text-center px-[15px]">
                <img src={image3} alt="icon" className="w-full max-w-[150px]" />
                <h6 className="text-[#FFFFFF] font-semibold text-[23px] mt-[15px] leading-[35px]">
                  Get Customized Service
                </h6>
                <p className="pt-[15px] text-lg lg:text-left lg:text-base text-[#EDEDED]">
                  Post your job for free in minutes and let VoiceMatch™ invite
                  the best voice actors suited for your project. Receive
                  high-quality auditions, compare quotes and hire.
                </p>

                <a
                  href="#"
                  className="text-[#00B5A5] text-[18px] mt-[15px] justify-center font-bold flex items-center gap-1"
                >
                  Talk to an Expert
                  <MdKeyboardDoubleArrowRight />
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
