// AccordionCustomIcon.jsx

import React, { useState } from "react";
import {
  Accordion as MaterialAccordion,
  AccordionHeader,
  AccordionBody,
} from "@material-tailwind/react";

function AccordionIcon({ id, open }) {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={2}
      stroke="currentColor"
      className={`${
        id === open ? "rotate-180" : ""
      } h-5 w-5 transition-transform`}
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 8.25l-7.5 7.5-7.5-7.5"
      />
    </svg>
  );
}

export function AccordionCustomIcon() {
  const [open, setOpen] = useState(false);

  const handleOpen = (value) => setOpen(open === value ? false : value);

  return (
    <>
      <MaterialAccordion
        open={open === 1}
        icon={<AccordionIcon id={1} open={open}/>}
      >
        <AccordionHeader onClick={() => handleOpen(1)}>
          What is Material Tailwind?
        </AccordionHeader>
        <AccordionBody className="px-4">
          We&apos;re not always in the position that we want to be at.
          We&apos;re constantly growing. We&apos;re constantly making mistakes.
          We&apos;re constantly trying to express ourselves and actualize our
          dreams.
        </AccordionBody>
      </MaterialAccordion>

      <MaterialAccordion
        open={open === 2}
        icon={<AccordionIcon id={2} open={open} />}
      >
        <AccordionHeader onClick={() => handleOpen(2)}>
          How to use Material Tailwind?
        </AccordionHeader>
        <AccordionBody className="px-4">
          We&apos;re not always in the position that we want to be at.
          We&apos;re constantly growing. We&apos;re constantly making mistakes.
          We&apos;re constantly trying to express ourselves and actualize our
          dreams.
        </AccordionBody>
      </MaterialAccordion>

      <MaterialAccordion
        open={open === 3}
        icon={<AccordionIcon id={3} open={open} />}
      >
        <AccordionHeader onClick={() => handleOpen(3)}>
          What can I do with Material Tailwind?
        </AccordionHeader>
        <AccordionBody className="px-4">
          We&apos;re not always in the position that we want to be at.
          We&apos;re constantly growing. We&apos;re constantly making mistakes.
          We&apos;re constantly trying to express ourselves and actualize our
          dreams.
        </AccordionBody>
      </MaterialAccordion>
    </>
  );
}

export default AccordionCustomIcon;
