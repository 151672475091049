import TabContent from "../Components/TabCon";
import Tabs from "../Components/Tabs";

export default function MainTabs() {
  const tabs = [
    { id: "talent", name: "Talent", component: <TabContent /> },
    { id: "packages", name: "Packages", component: <TabContent /> },
    // Add more tabs here if needed
  ];

  return (
    <section className="big-tab-sec relative py-[60px]">
      <div className="container mx-auto p-5 relative z-10 py-0">
        <Tabs tabs={tabs} />
      </div>
    </section>
  );
}
