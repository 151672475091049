import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";
import VideoCard from "./VideoCard";

export default function MultipleTabs() {
    const data = [
        {
            label: "Talent Account Setup",
            value: "Talent Account Setup",
            text: "Learn How to Set Up A Successful Voices Talent Profile",
            desc: `Best practices, tips, tricks, and general workflow suggestions to make sure that you are booking as much work as possible!`,
            videoLink: "https://www.youtube.com/embed/L0rRe0GujIY",
        },
        {
            label: "Product Demos",
            value: "Product Demos",
            text: "Learn How to Set Up A Successful Voices Talent Profile",
            desc: `Because it's about motivating the doers. Because I'm here
        to follow my dreams and inspire other people to follow their dreams, too.`,
            videoLink: "https://www.youtube.com/embed/L0rRe0GujIY",
        },
        {
            label: "Vue",
            value: "vue",
            text: "Learn How to Set Up A Successful Voices Talent Profile",
            desc: `We're not always in the position that we want to be at.
        We're constantly growing. We're constantly making mistakes. We're
        constantly trying to express ourselves and actualize our dreams.`,
        },
        {
            label: "Angular",
            value: "angular",
            text: "Learn How to Set Up A Successful Voices Talent Profile",
            desc: `Because it's about motivating the doers. Because I'm here
        to follow my dreams and inspire other people to follow their dreams, too.`,
        },
        {
            label: "Svelte",
            value: "svelte",
            text: "Learn How to Set Up A Successful Voices Talent Profile",
            desc: `We're not always in the position that we want to be at.
        We're constantly growing. We're constantly making mistakes. We're
        constantly trying to express ourselves and actualize our dreams.`,
        },
    ];

    return (
        <section className="mutlip-tabs">
            <div className="container m-auto p-5">
                <Tabs value="html" orientation="vertical" className="tab-boxs">
                    <TabsBody>
                        {data.map(({ value, desc, text, videoLink }) => (
                            <div key={value} className="flex flex-wrap p-0 boxes-inside">
                                <TabPanel
                                    key={value}
                                    value={value}
                                    className="py-0 w-full"
                                >
                                    <h2 className="text-[#0E1629] text-[45px] mb-[15px] leading-[55px] m-auto w-full font-bold">
                                        {" "}
                                        {text}
                                    </h2>

                                    <p className="mb-[35px] text-lg font-medium lg:text-base text-[#1B253D]">
                                        {desc}
                                    </p>

                                    <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[15px]">
                                        <VideoCard videoLink={videoLink} />
                                        <VideoCard videoLink={videoLink} />
                                        <VideoCard videoLink={videoLink} />
                                        <VideoCard videoLink={videoLink} />
                                        <VideoCard videoLink={videoLink} />
                                        <VideoCard videoLink={videoLink} />
                                    </div>
                                </TabPanel>
                            </div>
                        ))}
                    </TabsBody>

                    <TabsHeader className="w-[300px]">
                        <h6 className="text-black font-bold py-[13px] px-[25px] text-[18px]">
                            Video Categories
                        </h6>
                        {data.map(({ label, value }) => (
                            <Tab key={value} value={value}>
                                {label}
                            </Tab>
                        ))}
                    </TabsHeader>
                </Tabs>
            </div>
        </section>
    );
}
