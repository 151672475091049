import image from "../images/picture2.png";
import arrow from "../images/Group 15.png";


export default function WorksleftTxt() {
    return (
        <section className="image-text-blk md:py-[30px] py-[30px]">
            <div className="container m-auto p-5 pb-0">
                <div className="row grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 bg-[#DBF6F3] rounded-[15px] gap-[50px] items-center p-[50px]">
                    <div className="column">
                        <img src={image} alt="img" className="w-full" />
                    </div>

                    <div className="column">
                        <h2 className="text-[#0E1629] text-[45px] leading-[55px] w-full font-bold">
                            Browse & Buy Packages
                        </h2>
                        <p className="pt-3 text-lg font-normal lg:text-base text-[#5D5D5D]">
                            Skip the guesswork and the back and forth. Buy packages at pre‑determined prices and guaranteed timelines.
                        </p>

                        <ul className="list-none styles-ul">
                            <li className="text-lg font-normal lg:text-base">
                            Browse package listings by price, delivery days, and rating.
                            </li>

                            <li className="text-lg font-normal lg:text-base">
                            Select a pre-made sets of work or customize with add-ons for additional services.
                            </li>

                            <li className="text-lg font-normal lg:text-base">
                            Buy instantly then the voice actor gets to work. Download your files to complete your project.
                            </li>
                        </ul>

                        <button
                            type="button"
                            className="text-white flex gap-[15px] mt-[35px] mr-auto items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                        >
                            Browse Talent Packages
                            <img src={arrow} alt="icon" className="w-[30px]" />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}
