import Header from "../Components/header";
import Slider from "../Components/Slider";
import Work from "../Components/Work";
import Footer from "../Components/Footer";
import GuideBanner from "../Components/GuideBanner";
import ContentTabs from "../Components/ContentTabs";
import ContentList from "../Components/ContentList";

export default function Guide() {
    return (
        <div className="Guide">
            <Header />
            <Slider />
            <GuideBanner /> 
            <ContentList />           
            <ContentTabs />
            <Work />
            <Footer />
        </div>
    );
}
