import Header from "../Components/header";
import Slider from "../Components/Slider";
import Work from "../Components/Work";
import Footer from "../Components/Footer";
import HowBanner from "../Components/HowBanner";
import FindTalent from "../Components/FindTalent";
import WorksTxt from "../Components/WorksTxt";
import WorksleftTxt from "../Components/WorksleftTxt";
import ParentComponent from "../Components/ParentComponent";
import AddtoCart from '../Components/AddtoCart';
import VideoTxt from '../Components/VideoTxt';

function HowItWorks() {
    return (
        <div className="HowItWorks">
            <Header />
            <Slider />
            <HowBanner/>
            <FindTalent />
            <VideoTxt />
            <WorksTxt />
            <WorksleftTxt />
            <AddtoCart />
            <ParentComponent />
            <Work />
            <Footer />
        </div>
    ); 
}

export default HowItWorks;
