import { IoCloseOutline } from "react-icons/io5";

export default function LoginModal({ isModalOpen, onClose, children }) {
    return (
        <div onClick={onClose}
            className={`fixed inset-0 flex justify-center px-[15px] items-center transition-colors z-[9999] ${
                isModalOpen ? "visible bg-black/50" : "invisible"
            }`}
        >
            <div className="bg-white relative  rounded-xl max-w-[1000px] h-auto w-full" onClick={(e) => e.stopPropagation()}>
                <IoCloseOutline
                    onClick={onClose}
                    className="absolute top-2 right-2 cursor-pointer z-10 p-1 text-[#0E1629] w-full max-w-[45px] h-[45px]"
                />
                {children}
            </div>
        </div>
    );
}
