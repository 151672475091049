import image from "../images/Bell-logo 1.png";
import imageTwo from "../images/Cisco-logo 1.png";
import imageThree from "../images/Discovery-logo 1.png";
import imageFour from "../images/Shopify-logo 1.png";
import imageFIve from "../images/Godaddy-logo 1.png";
import imageSix from "../images/Group 13.png";

export default function Brands() {
  return (
    <>
      <section className="py-[30px] md:py-[60px]">
        <div className="container m-auto p-5">
          <div className="content flex flex-col	gap-5">
            <h2 className="text-center max-w-[900px] m-auto w-full">
              Creatives, marketers, producers, and instructors from the <b> world’s biggest brands and agencies trust Voices.</b>
            </h2>

            <div className="grid grid-cols-2 sm:grid-cols-4 md:grid-cols-6 gap-[35px] pt-[20px]">
                <div className="col">
                    <img src={image} alt="brands" className="w-full"/>
                </div>

                <div className="col">
                    <img src={imageTwo} alt="brands" className="w-full"/>
                </div>

                <div className="col">
                    <img src={imageThree} alt="brands" className="w-full"/>
                </div>

                <div className="col">
                    <img src={imageFour} alt="brands" className="w-full"/>
                </div>

                <div className="col">
                    <img src={imageFIve} alt="brands" className="w-full"/>
                </div>

                <div className="col">
                    <img src={imageSix} alt="brands" className="w-full"/>
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
