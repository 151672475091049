import { FaAngleLeft } from "react-icons/fa6";
import image from "../images/singleimage.png";
import { FaCircleCheck } from "react-icons/fa6";

export default function Steps() {
    return (
        <>
            <section>
                <div className="container m-auto p-[40px] pb-0 px-[20px]">
                    <div className="multisteps items-start justify-between mb-[20px]">
                        <button
                            type="button"
                            className="text-white flex gap-[15px] items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-bold rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                        >
                            <FaAngleLeft />
                            Back
                        </button>

                        <div className="user-image text-center">
                            <img
                                src={image}
                                alt="img"
                                className="max-w-[100px] w-full mx-auto"
                            />
                            <h6 className="flex items-center font-bold text-[20px] justify-center my-[10px]">
                                Lisa Mathew{" "}
                                <FaCircleCheck className="fill-[#007ED9] w-[30px]" />
                            </h6>
                            <p className="text-[#0E1629] text-[16px] leading-[23px] font-normal">
                                Last Online: 11 hours ago
                            </p>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
