export default function VideoCard({ videoLink }) {
    return (
        <div className="resource-sec">
            <div className="columns p-[15px] rounded-[15px] flex flex-col gap-[20px] items-start">
                <iframe src={videoLink} frameborder="0" className="w-full h-full rounded-[10px]"/>
                <span className="bg-[#DBF6F3] rounded-[5px] text-black text-[19px] leading-[23px] font-medium px-[15px] py-[6px]">
                    3m
                </span>
                <h6 className="text-[#0E1629] text-[22px] leading-[30px] font-medium">
                    How To Set Up a Profile on Voices
                </h6>
            </div>
        </div>
    );
}
