import { MdKeyboardDoubleArrowRight } from "react-icons/md";


export default function Work() {
  return (
    <>
      <section className="bg-[#DBF6F3] py-[30px] md:py-[60px]">
        <div className="container m-auto p-5 pt-0 pb-0">
          <div className="row grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-4 gap-[30px]">
            <div className="languages">
              <h5 className="text-[#0E1629] font-bold text-[25px] leading-[30px]">
                By Language
              </h5>

              <ul>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    English Voice Actor
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    English British Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    French Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    French Canadian Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    Spanish Voice Actors
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    German Voice Actors
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    Arabic Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#0E1629] text-[18px] font-bold flex items-center gap-1"
                  >
                    View All
                    <MdKeyboardDoubleArrowRight />
                  </a>
                </li>
              </ul>
            </div>

            <div className="languages">
              <h5 className="text-[#0E1629] font-bold text-[25px] leading-[30px]">
                By Language
              </h5>

              <ul>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    English Voice Actor
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    English British Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    French Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    French Canadian Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    Spanish Voice Actors
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    German Voice Actors
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    Arabic Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#0E1629] text-[18px] font-bold flex items-center gap-1"
                  >
                    View All
                    <MdKeyboardDoubleArrowRight />
                  </a>
                </li>
              </ul>
            </div>

            <div className="languages">
              <h5 className="text-[#0E1629] font-bold text-[25px] leading-[30px]">
                By Language
              </h5>

              <ul>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    English Voice Actor
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    English British Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    French Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    French Canadian Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    Spanish Voice Actors
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    German Voice Actors
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    Arabic Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#0E1629] text-[18px] font-bold flex items-center gap-1"
                  >
                    View All
                    <MdKeyboardDoubleArrowRight />
                  </a>
                </li>
              </ul>
            </div>

            <div className="languages">
              <h5 className="text-[#0E1629] font-bold text-[25px] leading-[30px]">
                By Language
              </h5>

              <ul>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    English Voice Actor
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    English British Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    French Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    French Canadian Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    Spanish Voice Actors
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    German Voice Actors
                  </a>
                </li>
                <li>
                  <a
                    href="#"
                    className="text-[#01BCAC] text-[18px] leading-[23px] font-medium"
                  >
                    Arabic Voice Actors
                  </a>
                </li>

                <li>
                  <a
                    href="#"
                    className="text-[#0E1629] text-[18px] font-bold flex items-center gap-1"
                  >
                    View All
                    <MdKeyboardDoubleArrowRight />
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
