import arrow from "../images/Group 15.png";

export default function Today() {
    return (
        <div className="py-[30px] md:py-[60px] bg-[#0E1629] relative">
            <div className="container m-auto p-5">
                <div className="">
                    <h2 className="text-[#fff] text-[45px] leading-[55px] m-auto w-full font-bold text-center">
                        Start Earning Today
                    </h2>
                    <p className="py-[30px] pt-3 text-center text-lg max-w-[750px] font-normal m-auto w-full lg:text-base text-[#fff]">
                        With total talent earnings over $100 million USD,
                        signing up to Voices opens you up to new earning
                        opportunities. So what are you waiting for? Create your
                        free profile today.
                    </p>

                    <button
                        type="button"
                        className="text-white flex gap-[15px] m-auto items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                    >
                        Get Started
                        <img src={arrow} alt="icon" className="w-[30px]" />
                    </button>
                </div>
            </div>
        </div>
    );
}
