import image from "../images/Voices.png";
import arrow from "../images/Group 15.png";

export default function FindWork() {
  return (
    <>
      <section className="bg-[#DBF6F3] pt-[30px] md:pt-[60px]">
        <div className="container m-auto p-5">
          <div className="row grid grid-cols-1 sm:grid-cols-2 gap-[35px] items-center">
            <div className="inside-col">
              <img src={image} alt="img" className="w-full rounded-[15px]" />
            </div>

            <div className="inside-col">
              <h2 className="text-[#0E1629] text-[45px] leading-[55px] m-auto w-full font-bold text-left">
                Get Voice Over Work
              </h2>
              <p className="pt-3 text-lg font-medium  text-[#5D5D5D]">
                Our members get access to 1000s of voice over jobs monthly.
                Create your profile and start booking voice over work today.
              </p>

              <ul>
                <li className="list-none">Unlock new opportunities</li>

                <li className="list-none">Create a new network of clients</li>

                <li className="list-none">Develop your portfolio</li>

                <li className="list-none">Build your online reputation</li>
              </ul>

              <div className="mt-[40px]">
                <button
                  type="button"
                  className="text-white flex gap-[15px] items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                >
                  Find Work
                  <img src={arrow} alt="icon" className="w-[30px]" />
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
