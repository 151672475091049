import Header from "../Components/header";
import Slider from "../Components/Slider";
import Work from "../Components/Work";
import Footer from "../Components/Footer";
import TutorialBanner from "../Components/TutorialBanner";
import FindTalent from "../Components/FindTalent";
import MultipleTabs from "../Components/MultipleTabs";

export default function Talents() {
    return (
        <div className="talents">
            <Header />
            <Slider />
            <TutorialBanner />
            <FindTalent />
            <MultipleTabs />
            <Work />
            <Footer />
        </div>
    );
}
