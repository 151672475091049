import React from 'react'
import Header from "../Components/header";
import Hello from "../Components/Slider";
import Work from "../Components/Work";
import Footer from "../Components/Footer";
import Narration from '../Components/Narration';
import Filters from '../Components/Filters';
import Brands from '../Components/Brands';
import ParentComponent from '../Components/ParentComponent';
import Talent from '../Components/Talent';
import Resources from '../Components/Resources';
import About from '../Components/About';
import Categories from '../Components/Categories';

function Video() {
  return (
    <div className='Video-Page'>
        <Header />
        <Hello />
        <Narration/>
        <Filters />
        <Brands />
        <ParentComponent />
        <Talent/>
        <Resources />
        <About />
        <Categories />
        <Work />
        <Footer />
    </div>
  )
}

export default Video