import Header from "../Components/header";
import Slider from "../Components/Slider";
import Work from "../Components/Work";
import Footer from "../Components/Footer";
import Subscription from "../Components/Subscription";
import ParentComponent from "../Components/ParentComponent";
import Crousel from "../Components/Crousel";
import SubscribePlans from "../Components/SubscribePlans";

export default function Membership() {
    return (
        <div className="Membership">
            <Header />
            <Slider />
            <Subscription />
            <SubscribePlans />
            <Crousel />
            <ParentComponent />
            <Work />
            <Footer />
        </div>
    );
}
