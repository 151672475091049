import { forwardRef, useEffect, useRef } from 'react';

const TextInput = forwardRef(function TextInput(
  { type = 'text', className = '', isFocused = false, ...props },
  ref
) {
  const innerRef = useRef();
  const inputRef = ref || innerRef;

  useEffect(() => {
    if (isFocused) {
      inputRef.current.focus();
    }
  }, [isFocused, inputRef]);

  return (
    <input
      {...props}
      type={type}
      className={
        'border-gray-300 focus:border-indigo-500 focus:ring-indigo-500 rounded-md shadow-sm ' +
        className
      }
      ref={inputRef}
    />
  );
});

export default TextInput;
