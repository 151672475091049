import image from "../images/timer.png";
import imagetwo from "../images/@.png";
import imagethree from "../images/call.png";
import imagefour from "../images/customer.png";

export default function Support() {
    return (
        <section className="pt-[30px] md:pt-[60px] pb-0 bg-[#DBF6F3]">
            <div className="container m-auto p-5 pb-0">
                <div className="row grid grid-cols-1 items-center sm:grid-cols-2 md:grid-cols-2">
                    <div className="inner-col">
                        <h2 className="text-[#0E1629] text-[45px] mb-[15px] leading-[55px] w-full font-bold">
                            Customer Support
                        </h2>
                        <p className="text-[#5D5D5D] text-[17px] leading-[23px] font-normal text-left">
                            We're here to help you. Contact our Customer Support
                            Team with any questions you may have.
                        </p>

                        <ul className="list-none">
                            <li className="flex items-center gap-[8px] my-[15px]">
                               <img src={image} alt="img" className="w-[35px]"/>
                               <span className="text-[#0E1629] text-[19px] leading-[23px] font-semibold">Mon - Fri, 8 AM - 8 PM (EST)</span>
                            </li>

                            <li className="flex items-center gap-[8px] my-[15px]">
                               <img src={imagetwo} alt="img" className="w-[35px]"/>
                               <span className="text-[#0E1629] text-[19px] leading-[23px] font-semibold">Email: support@voices.com</span>
                            </li>

                            <li className="flex items-center gap-[8px] my-[15px]">
                               <img src={imagethree} alt="img" className="w-[35px]"/>
                               <span className="text-[#0E1629] text-[19px] leading-[23px] font-semibold">1-888-359-3472</span>
                            </li>
                        </ul>
                    </div>

                    <div className="inner-col">
                        <img src={imagefour} alt="img" className="w-full"/>
                    </div>
                </div>
            </div>
        </section>
    );
}
