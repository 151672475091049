import React, { useState } from "react";
import { FaBarsStaggered } from "react-icons/fa6";
import { IoCloseSharp } from "react-icons/io5";
import LoginModal from "./LoginModal";
import Login from "../Pages/Auth/Login";
import { Link } from "react-router-dom";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  function handleClick() {
    setIsOpen(!isOpen);
  }
  function closeModal() {
    setIsModalOpen(false);
  }

  return (
    <>
      <header className="w-full relative text-gray-700 bg-white border-t border-gray-100 shadow-sm body-font">
        <div className="container flex items-center justify-between p-5 mx-auto">
          <div className="flex items-center navbar w-[33.33%]">
            <Link
              to="/FindWork"
              className="mx-[6px] font-bold hover:text-gray-900 cursor-pointer"
            >
              Find Work
            </Link>
            <Link
              to="/Talent"
              className="mx-[6px] font-bold hover:text-gray-900 cursor-pointer"
            >
              Find Talent
            </Link>
            <Link
              to="/HowItWorks"
              className="mx-[6px] font-bold hover:text-gray-900 cursor-pointer"
            >
              How It Works
            </Link>
          </div>

          {/* Toggle Button */}
          <div className="md:hidden">
            {isOpen ? (
              <IoCloseSharp
                className="w-8 h-8 cursor-pointer"
                onClick={handleClick}
              />
            ) : (
              <FaBarsStaggered
                className="w-8 h-8 cursor-pointer"
                onClick={handleClick}
              />
            )}
          </div>

          {/* Navigation Menu */}
          <nav
            className={`${
              isOpen ? "block" : "hidden"
            } absolute top-[80px] left-0 w-full p-[20px] bg-white z-10 transition-[.4s]`}
          >
            <Link
              to="/FindWork"
              className="font-bold hover:text-gray-900 block md:inline-block md:ml-5 my-[10px]"
            >
              Find Work
            </Link>
            <Link
              to="/HowItWorks"
              className="font-bold hover:text-gray-900 block md:inline-block md:ml-5 my-[10px]"
            >
              Find Talent
            </Link>
            <Link
              to="/HowItWorks"
              className="font-bold hover:text-gray-900 block md:inline-block md:ml-5 my-[10px]"
            >
              How It Works
            </Link>

            <a
              href="#"
              className="px-4 py-2 block w-max my-[10px] rounded-[8px] text-[17px] font-bold text-white bg-[#01bcac] transition-all duration-150 outline-none focus:outline-none ease"
            >
              Login
            </a>

            <a
              href=""
              className="font-medium w-max	block text-[#01BCAC] text-[17px]"
            >
              Sign Up
            </a>
          </nav>

          <a
            href="#"
            className="flex items-center logo-img w-[33.33%] order-first font-medium text-gray-900 lg:order-none title-font lg:items-center lg:justify-center md:mb-0"
          >
            <svg
              width="149"
              height="39"
              viewBox="0 0 149 39"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M136.743 38.832C129.463 38.832 124.887 35.556 124.887 30.356H134.351C134.351 31.968 135.235 32.956 136.743 32.956C137.939 32.956 138.719 32.228 138.719 31.032C138.719 30.044 138.147 29.42 137.003 29.056L132.167 27.704C127.695 26.508 125.303 23.752 125.303 19.8C125.303 14.652 129.619 11.376 136.171 11.376C143.139 11.376 147.663 14.548 147.663 19.488H138.459C138.459 17.928 137.679 17.044 136.223 17.044C135.079 17.044 134.351 17.668 134.351 18.708C134.351 19.696 135.027 20.476 136.379 20.892L141.423 22.348C145.895 23.7 148.027 26.092 148.027 29.992C148.027 35.4 143.659 38.832 136.743 38.832Z"
                fill="#0E1629"
              />
              <path
                d="M123.252 23.648C123.252 24.636 123.2 26.04 123.044 27.288H107.34C107.392 30.252 108.536 32.124 110.928 32.124C112.8 32.124 113.684 31.344 113.944 30.044H122.784C122.056 35.556 117.948 38.884 111.084 38.884C102.296 38.884 97.6682 34.048 97.6682 25.208C97.6682 16.472 102.452 11.376 110.876 11.376C118.728 11.376 123.252 16.16 123.252 23.648ZM107.34 21.984H113.944C113.944 18.812 112.904 17.304 110.772 17.304C108.432 17.304 107.34 18.812 107.34 21.984Z"
                fill="#0E1629"
              />
              <path
                d="M83.2959 38.884C75.2879 38.884 70.3479 33.58 70.3479 25.104C70.3479 16.732 75.3919 11.376 83.2959 11.376C91.0439 11.376 95.6719 15.224 95.7759 22.452H86.4159C86.3119 20.528 85.2199 19.488 83.4519 19.488C81.3199 19.488 80.0199 21.516 80.0199 25.104C80.0199 28.744 81.2679 30.772 83.5039 30.772C85.2199 30.772 86.2599 29.732 86.4159 27.86H95.7759C95.5159 35.088 90.9399 38.884 83.2959 38.884Z"
                fill="#0E1629"
              />
              <path
                d="M61.9978 11.168C58.7738 11.168 56.4338 8.72399 56.4338 5.75999C56.4338 2.74399 58.7738 0.299988 61.9978 0.299988C65.1178 0.299988 67.5098 2.74399 67.5098 5.75999C67.5098 8.72399 65.1178 11.168 61.9978 11.168ZM67.6138 38H57.8378V12.26H67.6138V38Z"
                fill="#0E1629"
              />
              <path
                d="M41.7654 38.884C33.4974 38.884 28.4534 33.476 28.4534 25.104C28.4534 16.732 33.4974 11.376 41.7654 11.376C50.0334 11.376 55.0774 16.732 55.0774 25.104C55.0774 33.476 50.0334 38.884 41.7654 38.884ZM41.7654 30.772C44.0534 30.772 45.4054 28.9 45.4054 25.104C45.4054 21.36 44.0534 19.488 41.7654 19.488C39.4774 19.488 38.1254 21.36 38.1254 25.104C38.1254 28.9 39.4774 30.772 41.7654 30.772Z"
                fill="#0E1629"
              />
              <path
                d="M21.112 38H10.14L0 1.59998H11.076L15.652 25.676L20.176 1.59998H31.252L21.112 38Z"
                fill="#0E1629"
              />
            </svg>
          </a>
          <div className="inline-flex w-[33.33%] multiple-btn items-center gap-[15px] h-full justify-end lg:ml-0">
            <a
              href="#"
              className="px-4 py-2 rounded-[8px] text-[17px] font-bold text-white bg-[#01bcac] transition-all duration-150 outline-none focus:outline-none ease"
              onClick={() => setIsModalOpen(true)}
            >
              Login
            </a>
            <LoginModal
              isModalOpen={isModalOpen}
              onClose={() => setIsModalOpen(false)}
            >
              <Login closeModal={closeModal} />
            </LoginModal>

            <a href="" className="font-medium text-[#01BCAC] text-[17px]">
              Sign Up
            </a>
          </div>
        </div>
      </header>
    </>
  );
};

export default Header;
