import image from "../images/Tablet.png";

export default function TextImage() {
  return (
    <section className="text-img-sec md:py-[60px] py-[30px]">
      <div className="container m-auto p-5">
        <div className="row grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 bg-[#F4F3F3] rounded-[15px] gap-[50px] items-center p-[50px]">
          <div className="column md:order-last">
            <img src={image} alt="img" className="w-full" />
          </div>

          <div className="column">
            <h2 className="text-[#0E1629] text-[45px] leading-[55px] w-full font-bold">
              Customize Your Voice with Our User-Friendly Tools
            </h2>
            <p className="pt-3 text-lg font-normal lg:text-base text-[#5D5D5D]">
              With our easy-to-use platform, you can hear your script come to
              life in real time. Modify the speed, pronunciation and add pauses
              to create a truly unique and engaging voice. Whether you want a
              slow, deliberate pace or a quick, upbeat tempo, you can create the
              perfect voice to match your content and captivate your audience.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
