import image from "../images/Vector (3).png";
import image2 from "../images/Vector (2).png";
import image3 from "../images/Vector (1).png";
import image4 from "../images/Group 90.png";
import image5 from "../images/Group 92.png";
import image6 from "../images/Group 94.png";

export default function Tools() {
  return (
    <>
      <section className="blog-section relative py-[30px] md:py-[60px]">
        <div className="container relative m-auto p-5 pt-0 z-10">
          <h2 className="text-[#0E1629] text-[45px] leading-[55px] m-auto w-full font-bold text-center">
            Tools and Resources for You To Succeed
          </h2>
          <p className="pt-3 text-center text-lg max-w-[690px] font-medium m-auto w-full lg:text-base text-[#1B253D]">
            Browse our resource library and latest content for help on all
            things creative, and all things Voices.
          </p>

          <div className="row grid grid-cols-1 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-6 gap-[20px] mt-[35px]">
            <div className="columns">
              <div className=" text-center">
                <span className="text-[#0E1629] text-[20px] leading-[27px] font-bold">
                Sample Scripts
                </span>
              </div>

              <div className="box p-[8px] rounded-full flex justify-center items-center w-[70px] h-[70px]">
                <img src={image} alt="img" className="w-[30px]" />
              </div>
            </div>

            <div className="columns">
              <div className=" text-center">
                <span className="text-[#0E1629] text-[20px] leading-[27px] font-bold">
                Videos
                </span>
              </div>

              <div className="box p-[8px] rounded-full flex justify-center items-center w-[70px] h-[70px]">
                <img src={image5} alt="img" className="w-[30px]" />
              </div>
            </div>

            <div className="columns">
              <div className=" text-center">
                <span className="text-[#0E1629] text-[20px] leading-[27px] font-bold">
                  Blog
                </span>
              </div>

              <div className="box p-[8px] rounded-full flex justify-center items-center w-[70px] h-[70px]">
                <img src={image4} alt="img" className="w-[30px]" />
              </div>
            </div>

            <div className="columns">
              <div className=" text-center">
                <span className="text-[#0E1629] text-[20px] leading-[27px] font-bold">
                  Potcasts
                </span>
              </div>

              <div className="box p-[8px] rounded-full flex justify-center items-center w-[70px] h-[70px]">
                <img src={image3} alt="img" className="w-[30px]" />
              </div>
            </div>

            <div className="columns ">
              <div className="text-center">
                <span className="text-[#0E1629] text-[20px] leading-[27px] font-bold">
                  Events
                </span>
              </div>

              <div className="box p-[8px] rounded-full flex justify-center items-center w-[70px] h-[70px]">
                <img src={image2} alt="img" className="w-[30px]" />
              </div>
            </div>

            <div className="columns">
              <div className=" text-center">
                <span className="text-[#0E1629] text-[20px] leading-[27px] font-bold">
                  Resources
                </span>
              </div>

              <div className="box p-[8px] rounded-full flex justify-center items-center w-[70px] h-[70px]">
                <img src={image6} alt="img" className="w-[30px]" />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
