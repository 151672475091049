import Header from "../Components/header";
import Hello from "../Components/Slider";
import StepSlider from "../Components/StepSlider";
import StepContent from "../Components/StepContent";
import Steps from "../Components/Steps";

export default function MultiStepform() {
    return (
        <div className="step-form">
            <Header />
            <Hello />
            <Steps />
            <StepContent />
            <StepSlider />
        </div>
    );
}
