export default function AnimateBan() {
  return (
    <section className="actors-sec relative py-[70px] bg-[#0E1629]">
      <div className="container p-5 m-auto relative z-10">
        <div className="banner-content text-center">
          <p className="text-[18px] text-white leading-[23px] font-semibold">
            Average Rating <span className="text-[#FFBC33]">5.0</span>
          </p>

          <h1 className="font-bold max-w-[950px] m-auto py-[25px] pb-0">
            Hire The Best Animation Voice Actor For Your Project
          </h1>
          <p className="pt-3 text-lg lg:pt-5 lg:text-base text-center text-[#EDEDED] max-w-[700px] m-auto">
            Browse voice actors to help bring your animation to life.
            <span className="text-[#01BCAC]"> See how Voices works</span> or
            <span className="text-[#01BCAC]"> Post a job</span> and have talent
            come to you!
          </p>
        </div>
      </div>
    </section>
  );
}
