// ParentComponent.js

import AccordionCustomIcon from "./Accordions";

function ParentComponent() {
  return (
    <section className="py-[30px] md:py-[60px] accordion-sec">
      <div className="container max-w-[1100px] w-full m-auto p-5 pt-0 pb-0">
        <h2 className="text-[#0E1629] text-[45px] mb-[15px] leading-[55px] m-auto w-full font-bold text-center">
        Frequently Asked Questions
        </h2>
        <AccordionCustomIcon />
      </div>
    </section>
  );
}

export default ParentComponent;
