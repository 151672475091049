import Home from "./Pages/Home";
import VoiceAI from "./Pages/VoiceAI";
import Talent from "./Pages/Talent";
import Video from "./Pages/Video";
import Online from "./Pages/Online";
import Animation from "./Pages/Animation";
import { Routes, Route } from "react-router-dom"
import Dashboard from "./Pages/Dashboard";
import FindWork from "./Pages/FindWork";
import HowItWorks from "./Pages/HowItWorks";
import Membership from "./Pages/Membership";
import MultiStepform from "./Pages/MultiStepform";
import SingleProfile from "./Pages/SingleProfile";
import Talents from "./Pages/Talents";
import Guide from "./Pages/Guide";
import Tutorials from "./Pages/Tutorials";

function App() {
  return (
    <>
      <div className="App">
      <Routes>
        <Route path="/" element={ <Home/> } />
        <Route path="VoiceAI" element={ <VoiceAI/> } />
        <Route path="Talent" element={ <Talent/> } />
        <Route path="Video" element={ <Video/> } />
        <Route path="Online" element={ <Online/> } />
        <Route path="Animation" element={ <Animation/> } />
        <Route path="Dashboard" element={ <Dashboard/> } />
        <Route path="FindWork" element={ <FindWork/> } />
        <Route path="Guide" element={ <Guide/> } />
        <Route path="HowItWorks" element={ <HowItWorks /> } />
        <Route path="Membership" element={ <Membership /> } />
        <Route path="MultiStepform" element={ <MultiStepform /> } />
        <Route path="SingleProfile" element={ <SingleProfile /> } />
        <Route path="Talents" element={ <Talents/> } />
        <Route path="Tutorials" element={ <Tutorials/> } />

      </Routes>
      </div>
    </>
  );
}

export default App;
