import React, { useState } from "react";
import CustomCheckbox from "../Components/CustomCheckbox";

export default function Checkboxes() {
  const initialCheckboxes = [
    { label: "Television Ad", ripple: true, isActive: false },
    { label: "Online Ad", ripple: false, isActive: false },
    { label: "Radio Ad", ripple: false, isActive: false },
    { label: "Video Narration", ripple: false, isActive: false },
    { label: "Elearning", ripple: false, isActive: false },
    { label: "Podcasting", ripple: false, isActive: false },
    { label: "Videogames", ripple: false, isActive: false },
    { label: "Telephone", ripple: false, isActive: false },
    { label: "Voice Assistant", ripple: false, isActive: false },
    { label: "Movie Trailers", ripple: false, isActive: false },
    { label: "Documentaries", ripple: false, isActive: false },
    { label: "Audiobooks", ripple: false, isActive: false },
    { label: "Animation", ripple: false, isActive: false },
    { label: "I'm not sure yet", ripple: false, isActive: false },
    // Add more checkboxes here as needed
  ];

  const [checkboxes, setCheckboxes] = useState(
    initialCheckboxes.map((checkbox, index) => ({
      ...checkbox,
      id: `checkbox-${index}`,
    }))
  );

  const handleCheckboxClick = (id) => {
    setCheckboxes((prevCheckboxes) =>
      prevCheckboxes.map((checkbox) =>
        checkbox.id === id
          ? { ...checkbox, isActive: !checkbox.isActive }
          : checkbox
      )
    );
  };

  return (
    <>
      <ul className="flex flex-wrap items-center gap-[15px] checkboxes-li py-[35px]">
        {checkboxes.map(({ id, label, ripple, isActive }) => (
          <CustomCheckbox
            key={id}
            id={id}
            label={label}
            ripple={ripple}
            isActive={isActive}
            onClick={handleCheckboxClick}
          />
        ))}
      </ul>
    </>
  );
}
