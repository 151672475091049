import SingleProfile from "./SingleProfile";
import Sample from "./Sample";

export default function ProfileDetails() {
    return (
        <section className="py-[30px] md:py-[90px] profile-section relative">
            <div className="container m-auto p-5">
                <div className="row grid grid-cols-12 gap-[30px] items-start">
                     <SingleProfile />
                     <Sample />
                </div>
            </div>
        </section>
    );
}
