import image from "../images/Voice.png";

export default function ImageText() {
  return (
    <section className="image-text-blk pb-0 md:py-[60px] py-[30px]">
      <div className="container m-auto p-5 pb-0">
        <div className="row grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 bg-[#DBF6F3] rounded-[15px] gap-[50px] items-center p-[50px]">
          <div className="column">
            <h2 className="text-[#0E1629] text-[45px] leading-[55px] w-full font-bold">
              Elevate Your Content With Professional Quality Text-To-Speech
            </h2>
            <p className="pt-3 text-lg font-normal lg:text-base text-[#5D5D5D]">
              Our text-to-speech platform uses clones of real, professional
              voice actors. As we navigate through the early stages of the
              product's beta phase, our goal is to consistently enhance the
              quality until it sounds human-like.
            </p>
          </div>

          <div className="column">
            <img src={image} alt="img" className="w-full" />
          </div>
        </div>
      </div>
    </section>
  );
}
