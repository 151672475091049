import Header from "../Components/header";
import Slider from "../Components/Slider";
import Work from "../Components/Work";
import ImageText from "../Components/ImageText"; 
import TextImage from "../Components/TextImage";
import HeadText from "../Components/HeadText";
import Speech from "../Components/Speech";
import Footer from "../Components/Footer";
import EditorDup from "../Components/EditorDup";
import WhyVoices from "../Components/WhyVoices";
import ParentComponent from "../Components/ParentComponent";

function Home() {
    return (
        <div className="Home">
            <Header />
            <Slider />
            <Speech />
            <ImageText />
            <TextImage />
            <HeadText />
            <EditorDup />
            <WhyVoices />
            <ParentComponent/>
            <Work />
            <Footer />
        </div>
    );
}

export default Home;
