import PlayContainer from "./Audio";
import songs from "../audio/Naina-Kbps.mp3";
import image from "../images/image.png";
import image2 from "../images/images.png";
import arrow from "../images/Group 15.png";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

export default function Edited() {
  return (
    <>
      <section className="relative edited-banner py-[30px] md:py-[60px] pt-0">
        <div className="container relative m-auto p-5 pt-0 z-10">
          <div className="row">
            <h4 className="text-[#0E1629] pl-[30px] font-bold text-[36px] leading-[43px] relative">
            Editor’s Package Picks
            </h4>
            <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-[25px] pt-[25px]">
              <div className="card-block p-[20px] bg-white rounded-[15px]">
                   <img src={image} alt="img" className="w-full" />

                   <div className="flex items-center justify-between pt-[15px]">
                      <h6 className="text-[#5D5D5D] text-[16px] leading-[23px] font-normal">What | Offer</h6>
                      <span className="text-[#0E1629] text-[16px] leading-[23px] font-normal p-[8px] bg-[#DBF6F3] rounded-[5px]">Movie Trailers</span>
                   </div>
                   <p className="text-[#0E1629] text-[19px] leading-[25px] font-medium py-[15px]">
                   A Dramatic, Comedic or Upbeat Voice Over for your Movie Trailer
                </p>
                  <PlayContainer audioSrc={songs} />
                  <div className="flex items-center justify-between mt-[15px]">
                  <a
                  href="#"
                  className="text-[#00B5A5] text-[18px] justify-center font-bold flex items-center gap-1"
                >
                   Read More
                  <MdKeyboardDoubleArrowRight />
                </a>
                    <p className="text-[#5D5D5D] font-medium text-[18px]">From <span className="text-[#0E1629] text-[25px] leading-[33px] font-bold">$189</span></p>
                  </div>
              </div>

              <div className="card-block p-[20px] bg-white rounded-[15px]">
                   <img src={image} alt="img" className="w-full" />

                   <div className="flex items-center justify-between pt-[15px]">
                      <h6 className="text-[#5D5D5D] text-[16px] leading-[23px] font-normal">What | Offer</h6>
                      <span className="text-[#0E1629] text-[16px] leading-[23px] font-normal p-[8px] bg-[#DBF6F3] rounded-[5px]">Movie Trailers</span>
                   </div>
                   <p className="text-[#0E1629] text-[19px] leading-[25px] font-medium py-[15px]">
                   A Dramatic, Comedic or Upbeat Voice Over for your Movie Trailer
                </p>
                  <PlayContainer audioSrc={songs} />
                  <div className="flex items-center justify-between mt-[15px]">
                  <a
                  href="#"
                  className="text-[#00B5A5] text-[18px] justify-center font-bold flex items-center gap-1"
                >
                   Read More
                  <MdKeyboardDoubleArrowRight />
                </a>
                    <p className="text-[#5D5D5D] font-medium text-[18px]">From <span className="text-[#0E1629] text-[25px] leading-[33px] font-bold">$189</span></p>
                  </div>
              </div>

              <div className="card-block p-[20px] bg-white rounded-[15px]">
                   <img src={image2} alt="img" className="w-full" />

                   <div className="flex items-center justify-between pt-[15px]">
                      <h6 className="text-[#5D5D5D] text-[16px] leading-[23px] font-normal">What | Offer</h6>
                      <span className="text-[#0E1629] text-[16px] leading-[23px] font-normal p-[8px] bg-[#DBF6F3] rounded-[5px]">Movie Trailers</span>
                   </div>
                   <p className="text-[#0E1629] text-[19px] leading-[25px] font-medium py-[15px]">
                   A Dramatic, Comedic or Upbeat Voice Over for your Movie Trailer
                </p>
                  <PlayContainer audioSrc={songs} />
                  <div className="flex items-center justify-between mt-[15px]">
                  <a
                  href="#"
                  className="text-[#00B5A5] text-[18px] justify-center font-bold flex items-center gap-1"
                >
                   Read More
                  <MdKeyboardDoubleArrowRight />
                </a>
                    <p className="text-[#5D5D5D] font-medium text-[18px]">From <span className="text-[#0E1629] text-[25px] leading-[33px] font-bold">$189</span></p>
                  </div>
              </div>
            </div>

            <div className="text-center pt-[50px]">
              <button
                type="button"
                className="text-white flex gap-[15px] items-center m-auto bg-[#0E1629]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
              >
                See More Packages
                <img src={arrow} alt="icon" className="w-[30px]" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
