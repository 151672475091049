
export default function StepContent() {
    return (
        <div className="container m-auto py-[40px] px-[20px]">
            <h4 className="text-[#0E1629] font-bold mb-[10px] text-[30px] leading-[40px]">
                Step 1
            </h4>
            <h6 className="text-[#0E1629] font-semibold mb-[10px] text-[25px] leading-[30px]">
                What type of voice do you need?
            </h6>
            <p className="text-[#0E1629] text-[17px] leading-[23px] font-normal">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry. Lorem Ipsum has been the industry's standard dummy
                text ever since the 1500s, when an unknown printer took a galley
                of type and scrambled it to make a type specimen book.
            </p>
        </div>
    );
}
