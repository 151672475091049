
export default function HeadText() {
    return (
        <section className="relative services-banner text-banner py-[30px] md:py-[60px]">
            <div className="container m-auto p-5 relative z-10">
                <div className="row grid grid-cols-1 md:grid-cols-2 gap-[50px]">
                    <h2 className="text-white text-[45px] leading-[55px] w-full font-bold">
                        Pay Only for What You Need
                    </h2>
                    <p className="text-left text-lg lg:text-base text-white">
                        Don’t get locked into an unnecessary subscription!
                        Voices offers flexible pricing options that are tailored
                        to your specific project. Pay only for what you need and
                        nothing more with our cost-per-word pricing.
                    </p>
                </div>
            </div>
        </section>
    );
}
