import React from 'react'
import AccordionCustomIcon from "./Accordions";

export default function Filter() {
    return (
        <section className="py-[60px] pb-[30px] accordion-sec">
          <div className="container max-w-[1100px] w-full m-auto p-5 pt-0 pb-0">
            <h2 className="text-[#0E1629] text-[45px] mb-[15px] leading-[55px] m-auto w-full font-bold text-center">
            Browse by Other Characteristics:
            </h2>
            <AccordionCustomIcon />
          </div>
        </section>
      );
}
