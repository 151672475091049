import image from "../images/resource.png";
import imagetwo from "../images/resourceone.png";
import graph from "../images/graph.png";
import imagefour from "../images/resourcetwo.png";

export default function Resources() {
  return (
    <section className="md:py-[60px] py-[30px] resource-sec">
      <div className="container m-auto p-5">
        <h2 className="text-[#0E1629] text-[45px] mb-[15px] leading-[55px] m-auto w-full font-bold text-center">
          Voice Over Resources Picked For You
        </h2>

        <div className="row grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 mt-[35px] gap-[25px]">
          <div className="columns p-[15px] rounded-[15px] flex flex-col gap-[20px]">
            <img src={image} alt="img" className="w-full" />
            <h6 className="text-[#0E1629] text-[22px] leading-[30px] font-medium">
            Voice Over Rates
            </h6>
          </div>

          <div className="columns p-[15px] rounded-[15px] flex flex-col gap-[20px]">
            <img src={imagetwo} alt="img" className="w-full" />
            <h6 className="text-[#0E1629] text-[22px] leading-[30px] font-medium">
              2023 Voice Over Trends Report
            </h6>
          </div>

          <div className="columns p-[15px] rounded-[15px] flex flex-col gap-[20px]">
            <img src={graph} alt="img" className="w-full" />
            <h6 className="text-[#0E1629] text-[22px] leading-[30px] font-medium">
            Ultimate Guide to Hiring Voice Actors
            </h6>
          </div>

          <div className="columns p-[15px] rounded-[15px] flex flex-col gap-[20px]">
            <img src={imagefour} alt="img" className="w-full" />
            <h6 className="text-[#0E1629] text-[22px] leading-[30px] font-medium">
              Voice Over Sample Scripts
            </h6>
          </div>
        </div>
      </div>
    </section>
  );
}
