import arrow from "../images/Group 15.png";
import { IoStar } from "react-icons/io5";

export default function Subscription() {
    return (
        <section className="py-[30px] md:py-[60px]">
            <div className="container m-auto p-5">
                <h2 className="text-[#0E1629] text-[45px] max-w-[700px] m-auto leading-[55px] w-full font-bold text-center">
                    Elevate your voice over career with a Voices membership.
                </h2>
                <div className="row grid mt-[40px] gap-[25px] grid-cols-1 sm:grid-cols-1 lg:grid-cols-2">
                    <div className="innr-columns rounded-[15px] p-[30px]">
                        <div className="txt-icons flex items-center justify-between">
                            <h6>Guest</h6>
                            <svg
                                width="68"
                                height="56"
                                viewBox="0 0 68 56"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    d="M8.5 39.2L0 11.2L20.128 22.4L34 0L47.872 22.4L68 11.2L59.5 39.2H8.5ZM8.5 44.8V56H59.5V44.8H8.5Z"
                                    fill="white"
                                />
                            </svg>
                        </div>

                        <h5 className="text-[#01BCAC] text-[40px] leading-[50px] font-bold">
                            $0
                        </h5>
                        <p>
                            Explore Voices with a free profile to promote your
                            skills.
                        </p>

                        <hr />
                        <ul className="list-none">
                            <li>
                                Create a free, SEO-optimized profile and upload
                                unlimited demos to showcase your skills
                            </li>
                            <li>
                                Receive private invitations to jobs from clients
                                who find you while searching the Voices website
                            </li>
                            <li>
                                Have peace of mind knowing that your payment is
                                held safely with our SurePay™ payment protection
                                service until your work is completed
                            </li>
                        </ul>

                        <button
                            type="button"
                            className="text-white flex justify-center gap-[15px] mt-[35px] items-center bg-[#01BCAC] font-bold rounded-lg text-lg w-full py-2.5 focus:outline-none"
                        >
                            Sign Up Free
                            <img src={arrow} alt="icon" className="w-[30px]" />
                        </button>

                        <button
                            type="button"
                            className="text-[#01BCAC] justify-center flex gap-[15px] mt-[20px] text-center bg-transparent rounded-lg text-lg w-full focus:outline-none font-bold"
                        >
                            Learn More
                        </button>
                    </div>

                    <div className="innr-columns rounded-[15px] p-[30px]">
                        <div className="txt-icons flex items-center justify-between">
                            <h6>Guest</h6>
                            <IoStar />
                        </div>

                        <h5 className="text-[#01BCAC] text-[40px] leading-[50px] font-bold">
                            $499{" "}
                            <span className="text-[25px] leading-[35px] font-normal">
                                {" "}
                                /year
                            </span>
                        </h5>
                        <p>
                            Accelerate your income with access to jobs that
                            match your profile.
                        </p>

                        <hr />
                        <ul className="list-none">
                            Enjoy all the benefits of a Guest membership, and:
                            <li>
                                Access and reply to jobs that best match your
                                profile, giving you the freedom to respond to
                                only the jobs that interest you
                            </li>
                            <li>
                                Increase your chances of being hired with custom
                                responses
                            </li>
                            <li>Get higher visibility in search results</li>
                            <li>
                                Track your progress and earnings with insights
                                from Statistics
                            </li>
                            <li>
                                Enhance your reputation on Voices by becoming
                                Voices Verified eligible
                            </li>
                        </ul>

                        <button
                            type="button"
                            className="text-white justify-center flex gap-[15px] mt-[35px] items-center bg-[#01BCAC]  font-bold rounded-lg text-lg w-full px-[10px] py-2.5  focus:outline-none"
                        >
                            Sign Up Free
                            <img src={arrow} alt="icon" className="w-[30px]" />
                        </button>

                        <button
                            type="button"
                            className="text-[#01BCAC] flex gap-[15px] justify-center mt-[20px] px-[10px] text-center bg-transparent rounded-lg text-lg w-full focus:outline-none font-bold"
                        >
                            Learn More
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}
