export default function Narration() {
  return (
    <section className="actors-sec relative py-[70px] bg-[#0E1629]">
      <div className="container p-5 m-auto relative z-10">
        <div className="banner-content text-center">
          <p className="text-[18px] text-white leading-[23px] font-semibold">
            Average Rating <span className="text-[#FFBC33]">5.0</span>
          </p>

          <h1 className="font-bold py-[25px] pb-0">
            Hire The Best Video Narration Voice Actor For Your Project
          </h1>
          <p className="pt-3 text-lg lg:pt-5 lg:text-base text-center text-[#EDEDED] max-w-[700px] m-auto">
            Quickly and easily hire professional video narration voice actors
            for your next explainer video, product video, or any of your online
            video needs. See <span className="text-[#01BCAC]">how Voices works</span> or <span className="text-[#01BCAC]">Post a job</span> and have talent come
            to you!
          </p>
        </div>
      </div>
    </section>
  );
}
