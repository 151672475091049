import React, {
    forwardRef,
    useImperativeHandle,
    useRef,
    useState,
} from "react";
import { FaCirclePlay, FaCirclePause } from "react-icons/fa6";

const PlayContainer = forwardRef(({ audioSrc, audioRefs, index }, ref) => {
    const audioRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [currentTime, setCurrentTime] = useState(0);
    const [duration, setDuration] = useState(0);

    useImperativeHandle(
        ref,
        () => ({
            stop() {
                if (audioRef.current) {
                    audioRef.current.pause();
                    audioRef.current.currentTime = 0;
                    setCurrentTime(0);
                    setIsPlaying(false);
                }
            },
        }),
        []
    );

    const togglePlay = () => {
        if (audioRefs.current && Array.isArray(audioRefs.current)) {
            audioRefs.current.forEach((audio, key) => {
                if (index !== key && audio && audio.stop) {
                    audio.stop();
                }
            });
        }

        if (isPlaying) {
            audioRef.current.pause();
        } else {
            audioRef.current.play().catch((error) => {
                console.error("Error playing audio:", error);
            });
        }
        setIsPlaying(!isPlaying);
    };

    const handleTimeUpdate = () => {
        setCurrentTime(audioRef.current.currentTime);
    };

    const handleDurationChange = () => {
        setDuration(audioRef.current.duration);
    };

    const formatTime = (time) => {
        const minutes = Math.floor(time / 60);
        const seconds = Math.floor(time % 60);
        return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };

    return (
        <div className="play-container p-[20px] rounded-[15px]">
            <div className="progress-bar-cover flex items-center pb-[8px] justify-between">
                <h6 className="text-[23px] text-[#01BCAC] leading-[30px] font-semibold">
                    Online Ads
                </h6>
                <span className="text-[18px] text-[#01BCAC] leading-[25px] font-semibold">
                    View Profile
                </span>
            </div>

            {audioRef.current && (
                <div
                    className="thumb"
                    style={{
                        left: `calc(${(currentTime / duration) * 100}% - 10px)`,
                    }}
                ></div>
            )}

            <div className="flex items-center">
                <audio
                    ref={audioRef}
                    onTimeUpdate={handleTimeUpdate}
                    onDurationChange={handleDurationChange}
                >
                    <source src={audioSrc} type="audio/mpeg" />
                    Your browser does not support the audio element.
                </audio>
                <button onClick={togglePlay}>
                    {isPlaying ? <FaCirclePause /> : <FaCirclePlay />}
                </button>
                {duration && (
                    <input
                        type="range"
                        className="w-[100%]"
                        min="0"
                        max={duration}
                        value={currentTime}
                        onChange={(e) => {
                            setCurrentTime(e.target.value);
                            audioRef.current.currentTime = e.target.value;
                        }}
                    />
                )}

                <span className="ml-[20px] text-[#01BCAC] font-medium inline-block">
                    {formatTime(currentTime)}
                </span>
            </div>
        </div>
    );
});

export default PlayContainer;
