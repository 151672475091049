import arrow from "../images/Group 15.png";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import image from "../images/people.png";

const options = {
  items: 1,
  nav: true,
  dots: false,
  stagePadding: 150,
  responsive: {
    0: {
      items: 1,
      stagePadding: 0,
    },
    768: {
      items: 1,
      stagePadding: 150,
    },
  },
};

export default function Slick() {
  return (
    <>
      <section className="slick-section py-[30px] md:py-[60px]">
        <div className="container m-auto p-5 pt-0">
          <div className="row grid grid-cols-12 gap-[10px] items-center">
            <h2 class="text-white text-[45px] leading-[55px] w-full font-bold col-span-3">
              What Our Clients Say
            </h2>
            <div className="btn-para col-span-9 flex flex-col gap-[20px]">
              <div class="">
                <p className="text-lg lg:text-base text-[#EDEDED]">
                  From big projects, tight-deadlines and quick turnarounds,
                  Voices can bring the perfect talent to you.
                </p>
              </div>

              <div className="">
                <button
                  type="button"
                  className="text-white flex gap-[15px] items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                >
                  See Our Client Success Stories
                  <img src={arrow} alt="icon" className="w-[30px]" />
                </button>
              </div>
            </div>
          </div>

          <OwlCarousel
            className="owl-theme py-[50px] text-center"
            loop
            margin={20}
            {...options}
            nav
          >
            <div className="item">
              <img src={image} alt="img" className="w-full" />
              <h6 className="text-white text-left text-[18px] pt-[20px] font-semibold">
              Moment Films
              <span className="text-white text-[16px] font-medium">(30 Second Television PSA)</span>
              </h6>
            </div>
            <div className="item">
              <img src={image} alt="img" className="w-full" />
              <h6 className="text-white text-left text-[18px] pt-[20px] font-semibold">
              Moment Films
              <span className="text-white text-[16px] font-medium">(30 Second Television PSA)</span>
              </h6>
            </div>
            <div className="item">
              <img src={image} alt="img" className="w-full" />
              <h6 className="text-white text-left text-[18px] pt-[20px] font-semibold">
              Moment Films
              <span className="text-white text-[16px] font-medium">(30 Second Television PSA)</span>
              </h6>
            </div>

            <div className="item">
              <img src={image} alt="img" className="w-full" />
              <h6 className="text-white text-left text-[18px] font-semibold pt-[20px]">
              Moment Films
              <span className="text-white text-left text-[16px] pt-[20px] font-medium">(30 Second Television PSA)</span>
              </h6>
            </div>

          </OwlCarousel>
        </div>
      </section>
    </>
  );
}
