

export default function Checklist() {
    return (
        <>
            <section className="relative bg-[#0E1629] py-[30px] md:py-[60px]">
                <div className="container relative m-auto p-5 z-10">
                    <div className="row">
                        <h2 className="text-white text-[45px] max-w-[1000px] leading-[55px] m-auto w-full font-bold text-center">
                            My Profile Checklist
                        </h2>

                        <div class="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 pt-[25px] gap-[25px]">
                            <div className="colums text-center p-[20px] bg-[#01BCAC14] rounded-[15px]">
                                <h6 className="text-[#FFFFFF] font-semibold text-[23px] mt-[15px] leading-[35px]">
                                    1 Demo Needed
                                </h6>
                                <p className="pt-[15px] text-lg text-center lg:text-base text-[#EDEDED]">
                                    Add a demo to appear in search and get
                                    matched to jobs.
                                </p>

                                <a
                                    href="#"
                                    className="text-[#fff] bg-[#00B5A5] w-fit m-auto text-[18px] px-[20px] py-[7px] mt-[15px] justify-center font-bold flex items-center gap-1 rounded-[10px]"
                                >
                                    Add
                                </a>
                            </div>

                            <div className="colums text-center p-[20px] bg-[#01BCAC14] rounded-[15px]">
                                <h6 className="text-[#FFFFFF] font-semibold text-[23px] mt-[15px] leading-[35px]">
                                    Live Directed Session Recommended
                                </h6>
                                <p className="pt-[15px] text-lg text-center lg:text-base text-[#EDEDED]">
                                    Specify whether you provide live directed
                                    sessions and which software you use.
                                </p>

                                <a
                                    href="#"
                                    className="text-[#fff] bg-[#00B5A5] px-[20px] py-[7px] w-fit m-auto  text-[18px] mt-[15px] justify-center font-bold flex items-center gap-1 rounded-[10px]"
                                >
                                    Add
                                </a>
                            </div>

                            <div className="colums text-center p-[20px] bg-[#01BCAC14] rounded-[15px]">
                                <h6 className="text-[#FFFFFF] font-semibold text-[23px] mt-[15px] leading-[35px]">
                                    9 Pieces of Information Recommended
                                </h6>
                                <p className="pt-[15px] text-lg text-center lg:text-base text-[#EDEDED]">
                                    Add these recommended details to your skill
                                    to improve your chances of being found in
                                    search.
                                </p>

                                <a
                                    href="#"
                                    className="text-[#fff] bg-[#00B5A5] px-[20px] py-[7px] w-fit m-auto  text-[18px] mt-[15px] justify-center font-bold flex items-center gap-1 rounded-[10px]"
                                >
                                    View
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}
