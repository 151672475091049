import { FaLocationDot } from "react-icons/fa6";
import { MdCall } from "react-icons/md";
import { FaLinkedinIn } from "react-icons/fa";
import { FaYoutube } from "react-icons/fa6";
import { FaInstagram } from "react-icons/fa";
import { FaTiktok } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa";

export default function Footer() {
    return (
        <>
            <div className="flex items-end w-full bg-[#0E1629]">
                <footer className=" text-white w-full">
                    <div className="container flex p-5 md:py-[60px] py-[30px] mx-auto md:items-center lg:items-start md:flex-no-wrap">
                        <div className="col-1 lg:w-[43%] w-full px-4">
                            <a className="flex items-center font-medium text-gray-900 title-font md:justify-start mb-[20px]">
                                <svg
                                    width="180"
                                    height="69"
                                    viewBox="0 0 260 69"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M239.761 68.1445C226.997 68.1445 218.973 62.4004 218.973 53.2829H235.567C235.567 56.1093 237.117 57.8417 239.761 57.8417C241.858 57.8417 243.226 56.5652 243.226 54.4682C243.226 52.7358 242.223 51.6417 240.217 51.0035L231.738 48.6329C223.897 46.5359 219.703 41.7036 219.703 34.7743C219.703 25.7479 227.27 20.0039 238.758 20.0039C250.976 20.0039 258.908 25.5656 258.908 34.2273H242.77C242.77 31.492 241.402 29.942 238.849 29.942C236.843 29.942 235.567 31.0361 235.567 32.8596C235.567 34.592 236.752 35.9596 239.123 36.689L247.967 39.2419C255.808 41.6125 259.546 45.8065 259.546 52.6447C259.546 62.1269 251.887 68.1445 239.761 68.1445Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M216.107 41.5213C216.107 43.2536 216.016 45.7153 215.742 47.9035H188.207C188.298 53.1005 190.304 56.3829 194.498 56.3829C197.781 56.3829 199.331 55.0152 199.786 52.7358H215.286C214.01 62.4004 206.807 68.2356 194.772 68.2356C179.363 68.2356 171.249 59.7563 171.249 44.2565C171.249 28.9391 179.637 20.0039 194.407 20.0039C208.175 20.0039 216.107 28.392 216.107 41.5213ZM188.207 38.6037H199.786C199.786 33.042 197.963 30.3979 194.225 30.3979C190.122 30.3979 188.207 33.042 188.207 38.6037Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M146.049 68.2356C132.008 68.2356 123.346 58.9358 123.346 44.0742C123.346 29.395 132.19 20.0039 146.049 20.0039C159.634 20.0039 167.748 26.7509 167.931 39.4242H151.519C151.337 36.0508 149.422 34.2273 146.322 34.2273C142.584 34.2273 140.305 37.7831 140.305 44.0742C140.305 50.4565 142.493 54.0123 146.413 54.0123C149.422 54.0123 151.246 52.1888 151.519 48.9065H167.931C167.475 61.5798 159.451 68.2356 146.049 68.2356Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M108.705 19.6386C103.053 19.6386 98.9497 15.3534 98.9497 10.1564C98.9497 4.86825 103.053 0.583008 108.705 0.583008C114.176 0.583008 118.37 4.86825 118.37 10.1564C118.37 15.3534 114.176 19.6386 108.705 19.6386ZM118.552 66.6851H101.411V21.5533H118.552V66.6851Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M73.23 68.2356C58.7332 68.2356 49.8892 58.7534 49.8892 44.0742C49.8892 29.395 58.7332 20.0039 73.23 20.0039C87.7269 20.0039 96.5709 29.395 96.5709 44.0742C96.5709 58.7534 87.7269 68.2356 73.23 68.2356ZM73.23 54.0123C77.2418 54.0123 79.6123 50.73 79.6123 44.0742C79.6123 37.5096 77.2418 34.2273 73.23 34.2273C69.2183 34.2273 66.8478 37.5096 66.8478 44.0742C66.8478 50.73 69.2183 54.0123 73.23 54.0123Z"
                                        fill="white"
                                    />
                                    <path
                                        d="M37.0172 66.684H17.7792L0 2.86133H19.4203L27.4438 45.0755L35.376 2.86133H54.7964L37.0172 66.684Z"
                                        fill="white"
                                    />
                                </svg>
                            </a>
                            <p className="my-[20px] text-[18px] leading-[23px] text-[#A0A0A0]">
                                Voices is the world’s #1 voice marketplace with
                                over 4 million members. Since 2005, the biggest
                                and most beloved brands have trusted Voices to
                                help them find professional voice talent to
                                bring their projects to life.
                            </p>

                            <h6 className="my-[20px] text-[25px] leading-[33px] font-bold text-[#01BCAC] title-font">
                                Contact Info
                            </h6>

                            <div className="flex items-center">
                                <FaLocationDot className="fill-[#01BCAC] mr-[10px]" />
                                <span className="text-[#A0A0A0] text-[18px] leading-[23px] font-normal">
                                    100 Dundas Street, Suite 700 London
                                </span>
                            </div>

                            <div className="flex items-center my-[15px]">
                                <MdCall className="fill-[#01BCAC] mr-[10px]" />
                                <span className="text-[#A0A0A0] text-[18px] leading-[23px] font-normal">
                                    1-888-359-3472
                                </span>
                            </div>
                        </div>

                        <div className="col-2 px-4 lg:w-[30.33%]">
                            <h6 className="mb-[20px] text-[25px] leading-[33px] font-bold text-[#01BCAC] title-font">
                                Company
                            </h6>
                            <nav className="mb-10 list-none">
                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                        About
                                    </a>
                                </li>
                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                        Contact
                                    </a>
                                </li>
                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                        Careers
                                    </a>
                                </li>

                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                        Press
                                    </a>
                                </li>

                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                        Reports
                                    </a>
                                </li>

                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                        Trust and Safety
                                    </a>
                                </li>

                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                        Affiliates
                                    </a>
                                </li>
                            </nav>
                        </div>

                        <div className="col-3 px-4 lg:w-[30.33%]">
                            <h6 className="mb-[20px] text-[25px] leading-[33px] font-bold text-[#01BCAC] title-font">
                                For Client
                            </h6>
                            <nav className="mb-10 list-none">
                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                    How It Works
                                    </a>
                                </li>
                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                    How Pricing Works
                                    </a>
                                </li>
                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                    Rate Guide
                                    </a>
                                </li>

                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                    Tools
                                    </a>
                                </li>

                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                    Leaderboard
                                    </a>
                                </li>

                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                    Managed Services
                                    </a>
                                </li>

                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                    Success Stories
                                    </a>
                                </li>
                            </nav>
                        </div>

                        <div className="col-4 px-4 lg:w-[30.33%]">
                            <h6 className="mb-[20px] text-[25px] leading-[33px] font-bold text-[#01BCAC] title-font">
                                For Talent
                            </h6>
                            <nav className="mb-10 list-none">
                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                    How It Works
                                    </a>
                                </li>
                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                    Help
                                    </a>
                                </li>
                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                    Resource Center
                                    </a>
                                </li>

                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                    Blog
                                    </a>
                                </li>

                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                    Podcast
                                    </a>
                                </li>

                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                    Coaches
                                    </a>
                                </li>

                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                    Events
                                    </a>
                                </li>

                                <li className="mt-3">
                                    <a className="text-[#A0A0A0] cursor-pointer">
                                    Memberships
                                    </a>
                                </li>
                            </nav>
                        </div>
                    </div>

                    <div className="social-footer">
                        <div className="container px-5 py-4 mx-auto">
                            <div className="flex md:justify-between justify-center items-center flex-wrap gap-[15px]">
                                <ul className="list-none flex gap-[25px]">
                                    <li>
                                        <a
                                            href="#"
                                            className="text-[#A0A0A0] text-[17px] leading-[23px] font-normal"
                                        >
                                            Terms of Service
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            href="#"
                                            className="text-[#A0A0A0] text-[17px] leading-[23px] font-normal"
                                        >
                                            Privacy Policy
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            href="#"
                                            className="text-[#A0A0A0] text-[17px] leading-[23px] font-normal"
                                        >
                                            Cookies
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="#"
                                            className="text-[#A0A0A0] text-[17px] leading-[23px] font-normal"
                                        >
                                            Accessibility
                                        </a>
                                    </li>

                                    <li>
                                        <a
                                            href="#"
                                            className="text-[#A0A0A0] text-[17px] leading-[23px] font-normal"
                                        >
                                            Site Map
                                        </a>
                                    </li>
                                </ul>
                                <div className="social-icon-footer flex">
                                    <span className="inline-flex justify-center mt-2 sm:ml-auto sm:mt-0 sm:justify-start gap-[15px]">
                                        <a className="text-gray-500 cursor-pointer hover:text-gray-700">
                                            <FaFacebookF />
                                        </a>
                                        <a className=" text-gray-500 cursor-pointer hover:text-gray-700">
                                            <FaTwitter />
                                        </a>
                                        <a className=" text-gray-500 cursor-pointer hover:text-gray-700">
                                            <FaLinkedinIn />
                                        </a>
                                        <a className=" text-gray-500 cursor-pointer hover:text-gray-700">
                                            <FaYoutube />
                                        </a>
                                        <a className=" text-gray-500 cursor-pointer hover:text-gray-700">
                                            <FaInstagram />
                                        </a>
                                        <a className=" text-gray-500 cursor-pointer hover:text-gray-700">
                                            <FaTiktok />
                                        </a>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="bg-[#0E1629]">
                        <div className="container px-5 py-4 mx-auto">
                            <p className="text-md font-light text-[#A0A0A0] capitalize text-center">
                                Copyright © 2023 Voices.com Inc. All rights
                                reserved.
                            </p>
                        </div>
                    </div>
                </footer>
            </div>
        </>
    );
}
