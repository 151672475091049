export default function About() {
  return (
    <section>
      <div className="container m-auto p-5">
        <div className="about-content">
          <h2 className="text-[#0E1629] text-[45px] mb-[15px] leading-[55px] m-auto w-full font-bold text-center">
            All About Video Narration Voice Over
          </h2>
          <p className="text-center mb-[35px] text-lg font-medium lg:text-base text-[#1B253D]">
            The internet is a big place and with online streaming capabilities
            improving every year, there’s no end in sight for the proliferation
            of video narration. Every day, millions of hours of video are
            uploaded online across platforms like YouTube, Vimeo, and social
            networks such as Facebook, Twitter, and Instagram. On YouTube alone,
            it’s reported that more than 500 hours of video is uploaded every
            minute. And at the same time, hungry audiences across the globe are
            consuming billions of hours of video content every day. If you’re a
            marketer or a content creator looking to stand out from the crowd
            and get your videos noticed, our professional video narration voice
            actors can help your online video stand out in this sea of content.
          </p>

          <div className="big-content p-[35px] rounded-[10px] bg-[#DBF6F3] mb-[25px]">
            <h6 className="text-[#000] text-[22px] leading-[30px] font-semibold">
              Video Narration project types
            </h6>
            <p className="pt-3 text-lg font-medium lg:text-base text-[#1B253D]">
              Video narration or online video voice actors voice every type of
              video out there, including internet ads, product demos, tutorials,
              and more. Whether it’s an explainer video guiding the audience
              through a complex concept, a how-to DIY, or this year’s holiday
              campaign, our experienced video narratino voice actors can deliver
              exactly what you’re looking for with flexibility and a quick
              turnaround.
            </p>
          </div>

          <div className="big-content p-[35px] rounded-[10px] bg-[#DBF6F3] mb-[25px]">
            <h6 className="text-[#000] text-[22px] leading-[30px] font-semibold">
              How to hire video narration voice actors
            </h6>
            <p className="pt-3 text-lg font-medium lg:text-base text-[#1B253D]">
              A video narration voice actor offers much more than just a voice
              that speaks over a video. These professionals take your creative
              direction and deliver a read in a way that only a trained voice
              actor can. Voice over experts are masters of the conversational
              read and know what it takes to deliver a relatable performance
              that doesn’t sound scripted. There’s nothing less appealing to
              audiences than a boring and stilted voice over, and that’s why
              creative professionals from around the globe trust our video
              narration voice actors to deliver compelling voice over that keeps
              audiences coming back for more.
            </p>
            <p className="pt-3 text-lg font-medium lg:text-base text-[#1B253D]">
              When it comes to creating a video narration that stands out, both
              your video and audio quality needs to be top-notch. Audio and
              video work in tandem to deliver your message, whether it’s
              marketing a new product or teaching a new skill, and audiences
              know what quality content should look, feel, and sound like. By
              hiring a professional video narration voice actor to voice your
              project, your video is sure to sound as great as it looks.
            </p>
          </div>

          <div className="big-content p-[35px] rounded-[10px] bg-[#DBF6F3] mb-[25px]">
            <h6 className="text-[#000] text-[22px] leading-[30px] font-semibold">
              Popular video narration for marketing
            </h6>
            <p className="text-lg pt-3 font-medium m-auto lg:text-base text-[#1B253D]">
              Explainer videos are some of the most popular video narration
              projects we see on the Voices platform. These marketing videos
              pair great visuals with engaging voice over to not only keep
              viewers tuned in, but also help digest and break down complex
              content. Brands use explainer videos to communicate their services
              and products in a succinct way, pairing creative visuals with
              voice over. Picture an animated video walking you through some
              frequently asked questions or a demo of a new website feature with
              narration overtop. The best video narration voice actors know how
              to take an informative script for an explainer video and bring it
              to life in a way that captures the attention of viewers.
            </p>

            <p className="text-lg pt-3 font-medium m-auto lg:text-base text-[#1B253D]">
              The top vocal quality creative professionals look for in this
              video narration is the believable voice over. In this style, voice
              actors infuse their read with meaning and trust often to carefully
              explain complex services and products. Another vocal quality
              creative professionals look for is the conversational style, which
              is a growing trend among every type of online video and many other
              projects. The conversational style is known for its casual and
              approachable sound, which is a skill honed by professional voice
              actors after many hours spent in the studio. Other vocal qualities
              creatives look for include kind, helpful, humorous, and confident.
            </p>
          </div>

          <div className="big-content p-[35px] rounded-[10px] bg-[#DBF6F3] mb-[25px]">
            <h6 className="text-[#000] text-[22px] leading-[30px] font-semibold">
              Voices makes hiring quality video narration voice actors easy
            </h6>
            <p className="text-lg pt-3 font-medium lg:text-base text-[#1B253D]">
              No matter what sound you’re looking for, you can find the perfect
              video narration voice over for your video quickly and easily on
              Voices. Take a listen our voice actors’ demos to find the right
              voice for your project.
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}
