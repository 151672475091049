import React, { useState } from "react";
import { motion } from "framer-motion";

function ProjectPlannerForm() {
  const [step, setStep] = useState(1);
  const totalSteps = 4; // Total number of steps in your form
  const [formData, setFormData] = useState({
    country: "",
    age: "",
    gender: "",
    additionalServices: "",
    projectName: "",
    projectDetails: "",
    file: null,
    select1: "",
    select2: "",
    radio1: "",
    radio2: "",
    select3: "",
    select4: "",
  });

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: type === "file" ? files[0] : value,
    }));
  };

  const nextStep = () => {
    if (step < totalSteps) setStep(step + 1);
  };

  const prevStep = () => {
    if (step > 1) setStep(step - 1);
  };

  const goToStep = (stepNumber) => {
    setStep(stepNumber);
  };

  const redoStep = () => {
    setStep(1);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log(formData);
  };

  return (
    <div className="relative flex step-slider">
      <div className="container mx-auto relative p-5">
        <form
          onSubmit={handleSubmit}
          className="py-[50px] step-slider px-[40px] bg-[#0E1629] rounded-[15px]"
        >
          {step === 1 && (
            <motion.div
              key={step}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className=""
            >
              <h4 className="text-[35px] leading-[45px] text-center font-bold text-[#fff] mb-[30px]">
                Booking Requirements
              </h4>

              <div className="py-[20px] pb-0">
                <label
                  htmlFor="countries"
                  className="block mb-[10px] text-md font-medium text-[#fff]"
                >
                  Language
                </label>

                <select
                  name="country"
                  className="mt-1 w-full text-[#D7D7D7] text-sm rounded-lg p-2.5 bg-[#0C333F] focus:outline-none"
                  value={formData.country}
                  onChange={handleChange}
                >
                  <option value="">Select Country</option>
                  <option value="USA">USA</option>
                  <option value="Canada">Canada</option>
                  {/* Add more countries as needed */}
                </select>
              </div>

              <div className="py-[20px] pb-0">
                <label
                  htmlFor="countries"
                  className="block mb-[10px] text-md font-medium text-[#fff]"
                >
                  Voice Age and Gender
                </label>

                <select
                  name="age"
                  className="w-full text-[#D7D7D7] text-sm rounded-lg p-2.5 bg-[#0C333F] focus:outline-none"
                  value={formData.age}
                  onChange={handleChange}
                >
                  <option value="">Select Age</option>
                  <option value="18-25">18-25</option>
                  <option value="26-35">26-35</option>
                </select>
              </div>

              <div className="py-[20px] pb-0">
                <label
                  htmlFor="countries"
                  className="block mb-[10px] text-md font-medium text-[#fff]"
                >
                  Additional Services Needed
                </label>

                <select
                  name="additionalServices"
                  className="w-full text-[#D7D7D7] text-sm rounded-lg p-2.5 bg-[#0C333F] focus:outline-none"
                  value={formData.additionalServices}
                  onChange={handleChange}
                >
                  <option value="">Select Additional Services</option>
                  <option value="Consulting">Consulting</option>
                  <option value="Development">Development</option>
                  {/* Add more services as needed */}
                </select>
              </div>

              <p className="font-light text-white mt-[15px]">
                <b>Note: </b> Additional services could increase the project
                cost; the price of these services will be set by the voice
                actor.
              </p>

              <div className="flex justify-end">
                <button
                  type="button"
                  onClick={nextStep}
                  className="mt-4 owl-next bg-black text-white font-bold py-2 px-4 rounded"
                ></button>
              </div>
            </motion.div>
          )}

          {step === 2 && (
            <motion.div
              key={step}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className=""
            >
              <h4 className="">Let’s talk budget & timelines</h4>

              <div>
                {/* Project name input field */}
                <input
                  type="text"
                  placeholder="Project Name"
                  name="projectName"
                  className="mt-4 w-full text-[#D7D7D7] text-sm rounded-lg p-2.5 bg-[#0C333F] focus:outline-none"
                  value={formData.projectName}
                  onChange={handleChange}
                />
              </div>

              <div>
                {/* Project details textarea */}
                <textarea
                  placeholder="Please provide a summary of your project"
                  name="projectDetails"
                  className="mt-4 border border-gray-400 w-full rounded-md px-4 py-3 focus:outline-none"
                  rows={4}
                  value={formData.projectDetails}
                  onChange={handleChange}
                />
              </div>

              <div>
                {/* File attachment input */}
                <input
                  type="file"
                  name="file"
                  className="mt-4 w-full text-[#D7D7D7] text-sm rounded-lg p-2.5 bg-[#0C333F] focus:outline-none"
                  onChange={handleChange}
                />
              </div>

              <div>
                {/* Select box 1 */}
                <select
                  name="select1"
                  className="mt-4 w-full text-[#D7D7D7] text-sm rounded-lg p-2.5 bg-[#0C333F] focus:outline-none"
                  value={formData.select1}
                  onChange={handleChange}
                >
                  <option value="">Select Option 1</option>
                  <option value="Option1">Option 1</option>
                  <option value="Option2">Option 2</option>
                </select>
              </div>

              <div>
                {/* Select box 2 */}
                <select
                  name="select2"
                  className="mt-4 w-full text-[#D7D7D7] text-sm rounded-lg p-2.5 bg-[#0C333F] focus:outline-none"
                  value={formData.select2}
                  onChange={handleChange}
                >
                  <option value="">Select Option 2</option>
                  <option value="Option1">Option 1</option>
                  <option value="Option2">Option 2</option>
                </select>
              </div>

              <div className="flex justify-between mt-12">
                <button
                  type="button"
                  onClick={prevStep}
                  className="mr-4 owl-prev bg-black text-white font-bold py-2 px-4 rounded"
                ></button>
                <button
                  type="button"
                  onClick={nextStep}
                  className="bg-black owl-next text-white font-bold py-2 px-4 rounded"
                ></button>
              </div>
            </motion.div>
          )}

          {step === 3 && (
            <motion.div
              key={step}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className=""
            >
              <h4 className="mt-12 text-3xl text-center">Give us the deets!</h4>

              <div className="flex justify-between mt-4">
                {/* Radio button 1 */}
                <div className="w-1/2 pr-2">
                  <label>
                    <input
                      type="radio"
                      name="radio1"
                      value="Option1"
                      checked={formData.radio1 === "Option1"}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    Option 1
                  </label>
                </div>

                {/* Radio button 2 */}
                <div className="w-1/2 pl-2">
                  <label>
                    <input
                      type="radio"
                      name="radio1"
                      value="Option2"
                      checked={formData.radio1 === "Option2"}
                      onChange={handleChange}
                      className="mr-2"
                    />
                    Option 2
                  </label>
                </div>
              </div>

              <div className="flex justify-between mt-4">
                {/* Select box 3 */}
                <div className="w-1/2 pr-2">
                  <select
                    name="select3"
                    className="w-full text-[#D7D7D7] text-sm rounded-lg p-2.5 bg-[#0C333F] focus:outline-none"
                    value={formData.select3}
                    onChange={handleChange}
                  >
                    <option value="">Select Option 3</option>
                    <option value="Option1">Option 1</option>
                    <option value="Option2">Option 2</option>
                  </select>
                </div>

                {/* Select box 4 */}
                <div className="w-1/2 pl-2">
                  <select
                    name="select4"
                    className="w-full text-[#D7D7D7] text-sm rounded-lg p-2.5 bg-[#0C333F] focus:outline-none"
                    value={formData.select4}
                    onChange={handleChange}
                  >
                    <option value="">Select Option 4</option>
                    <option value="Option1">Option 1</option>
                    <option value="Option2">Option 2</option>
                  </select>
                </div>
              </div>

              <div className="flex justify-between mt-12">
                <button
                  type="button"
                  onClick={prevStep}
                  className="mr-4 bg-black text-white font-bold py-2 px-4 rounded"
                ></button>
                <button
                  type="button"
                  onClick={nextStep}
                  className="bg-black text-white font-bold py-2 px-4 rounded"
                >
                  Submit
                </button>
              </div>
            </motion.div>
          )}

          {step === 4 && (
            <motion.div
              key={step}
              initial={{ opacity: 0, y: 20 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -20 }}
              transition={{ duration: 0.3 }}
              className="md:w-3/5 mx-auto py-12"
            >
              <div className="mt-12 text-base text-center">
                Your submission has been received! We will aim to get back to
                you within 3 working days.
              </div>
              <div>
                <div className="flex justify-between mt-12">
                  <button
                    type="button"
                    onClick={redoStep}
                    className="bg-black text-white font-bold py-2 px-4 rounded"
                  >
                    Redo the planner
                  </button>
                </div>
              </div>
            </motion.div>
          )}

          <div className="dots-container flex justify-center mt-4">
            {Array.from({ length: totalSteps }, (_, index) => (
              <div
                key={index}
                className={`dot mx-1 w-3 h-3 rounded-full cursor-pointer ${
                  step === index + 1 ? "bg-blue-600" : "bg-gray-400"
                }`}
                onClick={() => goToStep(index + 1)}
              ></div>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
}

export default ProjectPlannerForm;
