import MyDashboard from "../Components/MyDashboard";
import JobReview from "../Components/JobReview";
import Ready from "../Components/Ready";
import Checklist from "../Components/Checklist";
import Carrer from "../Components/Carrer";
import Support from "../Components/Support";
import Footer from "../Components/Footer";
import Authenticated from "../Layouts/AuthenticatedLayout";

export default function Dashboard() {
    return (
        <>

             <Authenticated/>
            <MyDashboard />
            <JobReview />
            <Ready />
            <Checklist />
            <Carrer />
            <Support />
            <Footer />
        </>
    );
}
