export default function WhyVoices () {
  return (
    <div className="py-[30px] md:py-[60px]">
      <div className="container m-auto p-5">
        <div className="back-layer">
          <h2 className="text-[#0E1629] text-[45px] leading-[55px] m-auto w-full font-bold text-center">
            Why Voices
          </h2>
          <p className="pt-3 text-center text-lg max-w-[900px] font-medium m-auto w-full lg:text-base text-[#1B253D]">
            Revolutionize your content with our unparalleled value proposition.
            With Voices, you gain access to a vast library of voices cloned from
            real, professional voice actors, ensuring that your text has a
            genuine and human touch. We believe in the power of authenticity,
            ethical AI and our tool empowers you to captivate listeners and
            leave a lasting impression.
          </p>
        </div>
      </div>
    </div>
  );
}
