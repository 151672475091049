import { useEffect, useState } from "react";
import Checkbox from "../../Components/Checkbox";
import GuestLayout from "../../Layouts/GuestLayout";
import InputError from "../../Components/InputError";
import InputLabel from "../../Components/InputLabel";
import PrimaryButton from "../../Components/PrimaryButton";
import TextInput from "../../Components/TextInput";
import { IoMdMail } from "react-icons/io";
import { IoEyeSharp } from "react-icons/io5";
import { HiMiniEyeSlash } from "react-icons/hi2";
import { NavLink } from "react-router-dom";

export default function Login({ status, canResetPassword }) {
    const [visible, setVisible] = useState(false);
    const [data, setData] = useState({
        email: "",
        password: "",
        remember: false,
    });
    const [errors, setErrors] = useState({});
    const [processing, setProcessing] = useState(false);

    useEffect(() => {
        return () => {
            setData((data) => ({ ...data, password: "" }));
        };
    }, []);

    const submit = async (e) => {
        e.preventDefault();
        setProcessing(true);
        setErrors({});

        // Replace this URL with your actual login endpoint
        const loginUrl = "/api/login";

        try {
            const response = await fetch(loginUrl, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });

            if (!response.ok) {
                throw new Error("Login failed");
            }

            // Handle successful login (e.g., redirect to dashboard)
            // const result = await response.json();
            // console.log(result);

            // Simulate login success
            alert("Login successful");
        } catch (error) {
            setErrors({ general: error.message });
        } finally {
            setProcessing(false);
        }
    };

    useEffect(() => {
        document.title = "Log in";
    }, []);

    return (
        <GuestLayout>
            {status && (
                <div className="mb-4 font-medium text-sm text-green-600">
                    {status}
                </div>
            )}

            <h3 className="text-[#0E1629] font-bold text-[40px] leading-[50px]">
                Log In to Voices
            </h3>
            <form onSubmit={submit}>
                <div className="relative">
                    <InputLabel
                        htmlFor="email"
                        value="Email"
                        className="font-normal text-[16px] leading-[20px] text-[#000]"
                    />

                    <TextInput
                        id="email"
                        type="email"
                        name="email"
                        value={data.email}
                        className="mt-1 block w-full"
                        autoComplete="username"
                        isFocused={true}
                        onChange={(e) => setData({ ...data, email: e.target.value })}
                    />
                    <IoMdMail className="absolute right-[5px] bottom-[7px] fill-[#0E1629] w-[20px] h-[20px]" />

                    <InputError message={errors.email} className="mt-2" />
                </div>

                <div className="mt-4 relative">
                    <InputLabel
                        htmlFor="password"
                        value="Password"
                        className="font-normal text-[16px] leading-[20px] text-[#000]"
                    />

                    <TextInput
                        id="password"
                        type={visible ? "text" : "password"}
                        name="password"
                        value={data.password}
                        className="mt-1 block w-full"
                        autoComplete="current-password"
                        onChange={(e) => setData({ ...data, password: e.target.value })}
                    />

                    <div
                        className="absolute right-[5px] bottom-[7px]"
                        onClick={() => setVisible(!visible)}
                    >
                        {visible ? (
                            <IoEyeSharp className="fill-[#0E1629] w-[20px] h-[20px]" />
                        ) : (
                            <HiMiniEyeSlash className="fill-[#0E1629] w-[20px] h-[20px]" />
                        )}
                    </div>

                    <InputError message={errors.password} className="mt-2" />
                </div>

                <div className="block mt-4">
                    <label className="flex items-center">
                        <Checkbox
                            name="remember"
                            checked={data.remember}
                            onChange={(e) => setData({ ...data, remember: e.target.checked })}
                        />
                        <span className="ms-2 text-sm text-[#000]">
                            Remember me
                        </span>
                    </label>
                </div>

                <div className="flex items-center justify-end mt-4">
                    {canResetPassword && (
                        <NavLink
                            to="/password/request"
                            className="underline text-sm text-[#000] hover:text-gray-900 rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                        >
                            Forgot your password?
                        </NavLink>
                    )}

                    <PrimaryButton className="ms-4" disabled={processing}>
                        Log in
                    </PrimaryButton>
                </div>

                {errors.general && (
                    <div className="mt-4 font-medium text-sm text-red-600">
                        {errors.general}
                    </div>
                )}
            </form>
        </GuestLayout>
    );
}
