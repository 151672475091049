import styles from "../css/Banner.module.css";
import image from "../images/AddBanner.png";

export default function AdBanner() {
  return (
    <>
      <div
        className={`${styles.banner} relative banner-sec`}
      >
        <div className="container relative p-5 m-auto z-10 pb-0 pt-0 flex flex-col items-start ">
          <div className="row flex items-center">
            <div className="col-left px-[15px] w-1/2 py-[60px]">
              <p className="text-[18px] text-white leading-[23px] font-semibold mb-[20px]">
                Average Rating <span className="text-[#FFBC33]">5.0</span>
              </p>
              <h1 className="font-bold lg:text-left">
                Hire The Best Voice Actor for Your Online Ad
              </h1>
              <p className="pt-3 text-lg lg:pt-5 lg:text-left lg:text-base text-[#EDEDED]">
                Find the perfect voice actor for your next online ad quickly and
                easily with access to global voice talent. Choose from over 100
                languages, accents, and dialects. See how voices work or post a
                job and have talent come to you!
              </p>
            </div>

            <div className="col-right w-1/2 px-[15px]">
              <img src={image} alt="img" className="w-full" />
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
