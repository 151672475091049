import Header from "../Components/header";
import Slider from "../Components/Slider";
import Work from "../Components/Work";
import Footer from "../Components/Footer";
import VideoBanner from "../Components/VideoBanner";
import FindTalent from "../Components/FindTalent"
function Tutorials() {
    return (
        <div className="Tutorials">
            <Header />
            <Slider />
            <VideoBanner />
            <FindTalent />
            <Work />
            <Footer />
        </div>
    );
}

export default Tutorials;
