import {
    Tabs,
    TabsHeader,
    TabsBody,
    Tab,
    TabPanel,
} from "@material-tailwind/react";

export default function ContentTabs() {
    const data = [
        {
            label: "Joining Voices",
            value: "Joining Voices",
            text: "Joining Voices",
            desc: `Best practices, tips, tricks, and general workflow suggestions to make sure that you are booking as much work as possible!`,
        },
        {
            label: "Completing Your profile",
            value: "Completing Your profile",
            text: "Is Voices right for me?",
            desc: `If you’re an experienced professional looking to expand your clientele and add big names to your portfolio, we can help. We open doors to working with some of the world’s largest brands because of our reputation and experience in the creative services online industry. Instead of you calling gatekeepers and spending precious time trying to make the right connections from home, we facilitate those relationships for you. This means you just focus on bringing your unique skills to every job opportunity and building another revenue stream while we handle the networking for you. \n If you’re new to the industry and looking for a place to get started we’ve got you covered, too. As an expert in the audio services industry, we’ve got the resources to support you as you get set up and best of all, we’ve got the connections to get you booking that first, fifth, and one-thousandth job. There’s nothing worse than getting all set up to do voice over work and then having no job opportunities. We’ve helped thousands of talents go from brand new to professional and we’d be happy to help you do the same—this industry is big enough for all of us.`,
        },
        {
            label: "Replying to Jobs",
            value: "Replying to Jobs",
            text: "What do I get with Voices?",
            desc: `Access to over 42,000 companies posting more than 5,000 jobs each month
            Hassle and fee-free payment
            Support network of Voices staff, resources, and fellow successful creatives here to help you grow your business `,
            videoLink: "https://www.youtube.com/embed/iussMlDoUVc",
        },
        {
            label: "Creating Packages",
            value: "Creating Packages",
            text: "Starting From My Home",
            desc: `Whether you’re a Guest or a subscribed member, MyHome is your landing page when you sign in to your account. It’s your headquarters for managing all aspects of working with us. You’ll see your`,
            videoLink: "https://www.youtube.com/embed/iussMlDoUVc",
        },
        {
            text: "Completing Your Profile",
            desc: `Your profile and your demos are essential to connect you to the right opportunities. They are your resume of work and your personal advertisement in our marketplace. Potential clients can learn about you, your experience, and the voice over skills you offer. They can view your profile, review your demos, read your ratings and reviews, and invite you to their job— right from your profile.`,
            videoLink: "https://www.youtube.com/embed/iussMlDoUVc",
        },
    ];

    return (
        <section className="mutlip-tabs">
            <div className="container m-auto p-5">
                <Tabs value="html" orientation="vertical" className="tab-boxs">
                    <TabsBody>
                        {data.map(({ value, desc, text, videoLink }) => (
                            <div
                                key={value}
                                className="flex flex-wrap p-0 boxes-inside"
                            >
                                <TabPanel
                                    key={value}
                                    value={value}
                                    className="py-0 w-full"
                                >
                                    <h2 className="text-[#0E1629] text-[45px] mb-[15px] leading-[55px] m-auto w-full font-bold">
                                        {" "}
                                        {text}
                                    </h2>

                                    <p className="mb-[35px] text-lg font-medium lg:text-base text-[#1B253D]">
                                        {desc}
                                    </p>
                                    <iframe src={videoLink} className="w-full h-[500px]"></iframe>
                                </TabPanel>
                            </div>
                        ))}
                    </TabsBody>

                    <TabsHeader className="w-[300px]">
                        <h6 className="text-black font-bold py-[13px] px-[25px] text-[18px]">
                            Table of Contents
                        </h6>
                        {data.map(({ label, value }) => (
                            <Tab key={value} value={value}>
                                {label}
                            </Tab>
                        ))}
                    </TabsHeader>
                </Tabs>
            </div>
        </section>
    );
}
