import styles from "../css/HowWorks.module.css";
import arrow from "../images/Group 15.png";

export default function HowBanner() {
    return (
        <section
            className={`${styles.sectionBanner} section-banner py-[70px]`}
        >
            <div className="container m-auto p-5">
                <div className="banner-content max-w-[450px] w-full mr-auto">
                    <h1 className="font-bold">How It Works</h1>
                    <p className="py-[25px] text-lg lg:text-left lg:text-base text-[#EDEDED]">
                        See how Voices connects producers, marketers,
                        instructors and educators at companies big and small to
                        the perfect voice actors for their projects.
                    </p>

                    <button
                        type="button"
                        className="text-white flex gap-[15px] mr-auto items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                    >
                        Get Started for Free
                        <img src={arrow} alt="icon" className="w-[30px]" />
                    </button>
                </div>
            </div>
        </section>
    );
}
