import { Breadcrumb } from "../Components/Breadcrumb"; 
import styles from '../css/NextBanner.module.css'; 
export default function NextBanner() {
  return (
    <section className={`${styles.sectionBanner} section-banner relative py-[60px]`}>
      <div className="container m-auto p-5 relative z-10">
        <Breadcrumb /> 
        <div className="banner-content max-w-[600px] w-full mr-auto">
          <h1 className="font-bold">Hire Professional Voice Actors</h1>
          <p className="pt-3 text-lg lg:pt-5 lg:text-left lg:text-base text-[#EDEDED]">
            Looking for award‑worthy voice over? Hire a trusted voice actor
            quickly and easily, keeping your creative project on budget, on
            time, and stress‑free. Find voice talent with the right language,
            style, or accent to bring your projects to life.
          </p>
        </div>
      </div>
    </section>
  );
}
