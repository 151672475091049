import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import image from "../images/Vector.png";

const options = {
  items: 3,
  nav: true,
  dots: true,
  responsive: {
    0: {
      items: 1,
    },
    768: {
      items: 3,
      dots: false,
    },
  },
};

export default function Crousel() {
  return (
    <>
      <section className="crousel-section relative py-[30px] md:py-[60px] bg-[#DBF6F3]">
        <div className="container m-auto p-5 pb-0">
          <div className="row">
            <OwlCarousel
              className="owl-theme text-center"
              loop
              margin={20}
              {...options}
              nav
            >
              <div className="item rounded-[20px] p-[20px] h-full">
                <img src={image} alt="img" className="w-full" />
                <p className="text-[#5D5D5D] text-[17px] leading-[23px] italic	font-normal mt-[15px]">
                  Working with a professional organization like Voices allows
                  people the flexibility to make their projects as good as
                  possible with as small of an effort on the producer’s side.
                  Voices helps find that exact niche voice that we’re looking
                  for for a client. It saves me a ton of time.
                </p>

                <h6 className="text-[#0E1629] text-[18px] leading-[25px] my-[15px] font-semibold">Peter Shannon</h6>
                <span className="text-[#01BCAC] text-[17px] leading-[23px] font-normal">
                  President of Memory Tree
                </span>
              </div>
              <div className="item rounded-[20px] p-[20px] h-full">
                <img src={image} alt="img" className="w-full" />
                <p className="text-[#5D5D5D] text-[17px] leading-[23px] italic	font-normal mt-[15px]">
                  Script sent one day, get voices back the next. By the end of
                  the week we had a great voice selected, as well as the
                  recordings we needed.
                </p>

                <h6 className="text-[#0E1629] text-[18px] leading-[25px] my-[15px] font-semibold">Peter Shannon</h6>
                <span className="text-[#01BCAC] text-[17px] leading-[23px] font-normal">
                  President of Memory Tree
                </span>
              </div>
              <div className="item rounded-[20px] p-[20px] h-full">
                <img src={image} alt="img" className="w-full" />
                <p className="text-[#5D5D5D] text-[17px] leading-[23px] italic	font-normal mt-[15px]">
                  With all of these savings, quality was not sacrificed because
                  Voices has access to the best talent in the world. It all
                  comes down to the delivery of broadcast-ready content for the
                  client at incredible speed and quality.
                </p>

                <h6 className="text-[#0E1629] text-[18px] leading-[25px] my-[15px] font-semibold">Peter Shannon</h6>
                <span className="text-[#01BCAC] text-[17px] leading-[23px] font-normal">
                  President of Memory Tree
                </span>
              </div>

              <div className="item rounded-[20px] p-[20px] h-full">
                <img src={image} alt="img" className="w-full" />
                <p className="text-[#5D5D5D] text-[17px] leading-[23px] italic	font-normal mt-[15px]">
                  Working with a professional organization like Voices allows
                  people the flexibility to make their projects as good as
                  possible with as small of an effort on the producer’s side.
                  Voices helps find that exact niche voice that we’re looking
                  for for a client. It saves me a ton of time.
                </p>    

                <h6 className="text-[#0E1629] text-[18px] leading-[25px] my-[15px] font-semibold">Peter Shannon</h6>
                <span className="text-[#01BCAC] text-[17px] leading-[23px] font-normal">
                  President of Memory Tree
                </span>
              </div>
            </OwlCarousel>
          </div>
        </div>
      </section>
    </>
  );
}
