import styles from "../css/Cards.module.css";
import { MdKeyboardDoubleArrowRight } from "react-icons/md";

export default function Cards() {
  return (
    <>
      <section className={`${styles.cardbg} relative py-[30px] md:py-[60px] cards-sec`}>
        <div className="container relative m-auto p-5 z-10">
          <div className="row">
            <h2 className="text-white text-[45px] max-w-[1000px] leading-[55px] m-auto w-full font-bold text-center">Your Go-to Source for All Professional Voice Over Needs</h2>
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4 pt-[25px]">
              <div className="columns border-1 bg-[#fff] rounded-[20px]">
                <h6 className="text-[25px] text-[#0E1629] mb-[10px] font-semibold leading-[33px]">
                  Find the Perfect Voice for Your Project
                </h6>
                <p className="text-[#5D5D5D] text-[18px]">
                  Access our diverse talent pool of over 4,000,000 voice actors
                  across 160 countries.
                </p>

                <div className="button-col mt-[20px]">
                  <a href="#" className="text-[#00B5A5] text-[18px] font-bold flex items-center gap-1">
                    Browse talent
                    <MdKeyboardDoubleArrowRight />
                  </a>
                </div>
              </div>

              <div className="columns border-1 bg-[#fff] rounded-[20px]">
                <h6 className="text-[25px] text-[#0E1629] mb-[10px] font-semibold leading-[33px]">
                  Get Projects Done Faster When Hiring Direct
                </h6>
                <p className="text-[#5D5D5D] text-[18px]">
                  Our marketplace lets you directly hire talent and receive
                  final files, often in as little as a day.
                </p>

                <div className="button-col mt-[20px]">
                  <a href="#" className="text-[#00B5A5] text-[18px] font-bold flex items-center gap-1">
                    Browse talent
                    <MdKeyboardDoubleArrowRight />
                  </a>
                </div>
              </div>

              <div className="columns border-1 bg-[#fff] rounded-[20px]">
                <h6 className="text-[25px] text-[#0E1629] mb-[10px] font-semibold leading-[33px]">
                  High Quality Voice Over, Competitive Rates
                </h6>
                <p className="text-[#5D5D5D] text-[18px]">
                  Receive quotes and auditions for free so you can choose the
                  best voice actor for your project.
                </p>

                <div className="button-col mt-[20px]">
                  <a href="#" className="text-[#00B5A5] text-[18px] font-bold flex items-center gap-1">
                    Browse talent
                    <MdKeyboardDoubleArrowRight />
                  </a>
                </div>
              </div>

              <div className="columns border-1 bg-[#fff] rounded-[20px]">
                <h6 className="text-[25px] text-[#0E1629] mb-[10px] font-semibold leading-[33px]">
                  Ready-to-Use, Broadcast Quality Files
                </h6>
                <p className="text-[#5D5D5D] text-[18px]">
                  Our voice actors are invested in their careers, with
                  professional audio equipment and software.
                </p>

                <div className="button-col mt-[20px]">
                  <a href="#" className="text-[#00B5A5] text-[18px] font-bold flex items-center gap-1">
                    Browse talent
                    <MdKeyboardDoubleArrowRight />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
