
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { Link } from "react-router-dom";

const options = {
    items: 7,
    nav: true,
    dots: false,
    responsive: {
        0: {
            items: 1,
        },

        575: {
            items: 3,
        },
        768: {
            items: 5,
        },
        1024: {
            items: 7,
        },
    },
};

export default function Slider() {
    return (
        <section className="bg-[#01BCAC] small-slider relative">
            <div className="container m-auto p-5">
                <OwlCarousel
                    className="owl-theme py-[8px] text-center"
                    loop
                    margin={10}
                    {...options}
                    nav
                >
                    <div className="item">
                        <Link
                            to="/VoiceAI"
                            className="text-white text-[16px] text-center"
                        >
                            AI Voice Beta
                        </Link>
                    </div>
                    <div className="item">
                        <Link
                            to="/Talent"
                            className="text-white text-[16px] text-center">
                            Browse Talent
                        </Link>
                    </div>
                    <div className="item">
                        <Link
                            to="/Video"
                            className="text-white text-[16px] text-center"
                        >
                            Video Narration
                        </Link>
                    </div>
                    <div className="item">
                        <Link
                            to="/Online"
                            className="text-white text-[16px] text-center"
                        >
                            Online Ad
                        </Link>
                    </div>

                    <div className="item">
                        <Link
                            to="/Animation"
                            className= "text-white text-[16px] text-center"
                        >
                            Animation
                        </Link>
                    </div>
                    <div className="item">
                        <Link
                            to="/"
                            className="text-white text-[18px] text-center"
                        >
                            Voice Assistant
                        </Link>
                    </div>
                    <div className="item">
                        <Link
                            to="/"
                            className= "text-white text-[16px] text-center"
                        >
                            Audiobooks
                        </Link>
                    </div>
                </OwlCarousel>
            </div>
        </section>
    );
}
