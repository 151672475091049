import { useState } from "react";
import image from "../images/user.png";
import { FaCirclePlay } from "react-icons/fa6";
import arrow from "../images/Group 15.png";

export default function Speech() {
  const [isOpen, setIsOpen] = useState(false);
  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  return (
    <>
      <section className="relative services-banner py-[60px]">
        <div className="container relative m-auto p-5 pt-0 z-10">
          <div className="row">
            <h2 className="text-white text-[45px] max-w-[1000px] leading-[55px] m-auto w-full font-bold text-center">
              Authentic Text-to-Speech Voices by Professional Voice Actors
            </h2>
            <p className="pt-3 text-center text-lg lg:text-base text-[#EDEDED]">
              Voices' text-to-speech AI Studio delivers AI voices from real,
              professional voice actors. Now in its early stage beta, try it
              today!
            </p>

            <div className="text-center mt-[35px]">
              <button
                type="button"
                className="text-white  m-auto flex gap-[15px] items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
              >
                Try the AI Studio Beta
                <img src={arrow} alt="icon" className="w-[30px]" />
              </button>
            </div>

            <div className="box-container mt-[35px] bg-[#01BCAC14] rounded-[15px] p-[15px]">
                <div className="inner-container flex flex-col gap-[20px] rounded-[10px] bg-[#0C333F]">
                <div className="box-inside grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 p-[15px] items-center">
                <div className="left-box flex items-center gap-[10px]">
                  <img
                    src={image}
                    alt="img"
                    className="max-w-[70px] w-full p-[5px] bg-white rounded-full"
                  />

                  <div className="content-box">
                    <h6 className="text-[#fff] text-[18px] leading-[25px] font-semibold">
                      Rachael West
                    </h6>
                    <p className="text-[#fff] text-[14px] leading-[22px] font-light">
                      North American (General)
                    </p>
                    <p className="text-[#fff] text-[14px] leading-[22px] font-light">
                      Middle Aged (35-54) Narrator
                    </p>
                  </div>
                </div>

                <div className="btn-speech relative text-end">
                  <button
                    id="dropdownDefaultButton"
                    onClick={toggleDropdown}
                    className="text-white bg-[#F4F4F433] focus:ring-4 focus:outline-none font-normal rounded-lg text-sm px-5 py-2.5 text-center inline-flex items-center"
                    type="button"
                  >
                    See More AI Voices
                    <svg
                      className="w-2.5 h-2.5 ms-3"
                      aria-hidden="true"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 10 6"
                    >
                      <path
                        stroke="currentColor"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="m1 1 4 4 4-4"
                      />
                    </svg>
                  </button>
                  {/* Dropdown menu */}
                  {isOpen && (
                    <div
                      id="dropdown"
                      className="z-10 absolute right-0 mt-2 w-44 bg-white divide-y divide-gray-100 rounded-lg shadow dark:bg-gray-700"
                    >
                      <ul
                        className="py-2 text-sm text-gray-700 dark:text-gray-200"
                        aria-labelledby="dropdownDefaultButton"
                      >
                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Dashboard
                          </a>
                        </li>

                        <li>
                          <a
                            href="#"
                            className="block px-4 py-2 hover:bg-gray-100 dark:hover:bg-gray-600 dark:hover:text-white"
                          >
                            Earnings
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>

              <textarea
                id="message"
                rows="4"
                className="block p-2.5 w-full text-sm text-white-900 rounded-lg bg-[#01bcac14] resize-none"
                placeholder="Write your thoughts here..."
              ></textarea>

              <div className="preview-btn text-center">
                <button
                  type="button"
                  className="text-white flex gap-[15px] m-auto items-center bg-transparent font-bold rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                >
                  Preview
                  <FaCirclePlay />
                </button>
              </div>
                </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
