import arrow from "../images/Group 15.png";

export default function Ready() {
    return (
        <section className="py-[60px] bg-[#DBF6F3]">
            <div className="container p-5 m-auto">
                <div className="text-center">
                    <h2 className="text-[#0E1629] text-[45px] leading-[55px] max-w-[600px] w-full mx-auto font-bold text-center">
                        Ready to take your creative career to the next level?
                    </h2>
                    <p className="text-[#5D5D5D] text-[19px] leading-[23px] font-normal py-[20px]">
                        Unlock the ability to respond to unlimited public
                        invitations to jobs with a Premium membership.
                    </p>

                    <div className="mt-[20px] flex items-center justify-center gap-[20px]">
                        <button
                            type="button"
                            className="text-white flex gap-[15px] items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5 text-left focus:outline-none"
                        >
                            Learn More
                            <img src={arrow} alt="icon" className="w-[30px]" />
                        </button>

                        <button
                            type="button"
                            className="text-[#01BCAC] border border-solid premium-btn border-[#01BCAC] flex gap-[15px] items-center bg-transparent  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                        >
                            Upgrade To Premium
                            <img src={arrow} alt="icon" className="w-[30px]" />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}
