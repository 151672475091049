import Header from "../Components/header";
import Slider from "../Components/Slider";
import Work from "../Components/Work";
import Footer from "../Components/Footer";
import WorkBanner from "../Components/WorkBanner";
import EarnBoxes from "../Components/EarnBoxes";
import Today from "../Components/Today";

function FindWork() {
    return (
        <div className="FindWork">
            <Header />
            <Slider />
            <WorkBanner />
            <EarnBoxes />
            <Today />
            <Work />
            <Footer />
        </div>
    );
}

export default FindWork;
