export default function VideoTxt() {
    return (
        <section className="py-[30px] bg-[#DBF6F3]">
            <div className="container m-auto p-5 pb-0">
                <h2 className="text-[#0E1629] text-[45px] mb-[15px] leading-[55px] m-auto w-full font-bold text-center">
                    Find Talent, Your Way
                </h2>
                <p className="text-center mb-[35px] max-w-[700px] m-auto w-full text-lg font-medium lg:text-base text-[#1B253D]">
                    Start your project by using one of our three ways of finding
                    talent. Whatever way you choose, we keep your money safe
                    until the job is done and you are satisfied.
                </p>
                <div className="video-container relative pb-[56.25%] h-0">
                    <iframe
                        src="https://www.youtube.com/embed/L0rRe0GujIY"
                        className="w-full h-full absolute top-0 left-0 rounded-[15px]"
                        title="Embedded Video"
                        allowFullScreen
                    />
                </div>
            </div>
        </section>
    );
}
