import React from 'react'
import arrow from "../images/Group 15.png";


export default function Actors() {
  return (
    <div className="py-[30px] md:py-[60px] bg-[#0E1629] actors-sec relative">
      <div className="container m-auto p-5 relative z-10">
        <div className="">
          <h2 className="text-[#fff] text-[45px] leading-[55px] m-auto w-full font-bold text-center">
          Let Voice Actors Come To You
          </h2>
          <p className="py-[30px] pt-3 text-center text-lg max-w-[900px] font-medium m-auto w-full lg:text-base text-[#fff]">
          Sign Up, Post a Job and Have Custom Auditions Sent to You In Minutes
          </p>

          <button
          type="button"
          className="text-white flex gap-[15px] m-auto items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
        >
          Sign Up Free
          <img src={arrow} alt="icon" className="w-[30px]" />
        </button>
        </div>
      </div>
    </div>
  )
}
