import image from "../images/loginimg.png";

export default function Guest({ children }) {
    return (
        <div className="flex sm:justify-center pt-6 sm:pt-0 rounded-l-xl">
            <div className="w-1/2">
                <img src={image} className="w-full h-[500px] object-cover rounded-l-xl" alt="img"/>
            </div>

            <div className="login-right-con relative w-1/2 p-10 bg-white overflow-hidden sm:rounded-lg">
                {children}
            </div>
        </div>
    );
}
