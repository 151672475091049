import React, { useRef } from "react";
import SongsSample from "./SongsSample";
import songSrc from "../audio/Naina-Kbps.mp3";

export default function Sample() {
    const audioRefs = useRef([]);
    const numberOfSongs = 5;

    // Create an array of unique audio sources for each song
    const songs = Array.from({ length: numberOfSongs }, (_, index) => ({
        src: `${songSrc}?${index}`,
    }));

    return (
        <div className="samples col-span-12 lg:col-span-4 rounded-[15px]">
            <h6 className="font-bold text-[25px] leading-[30px]">
                Voice Samples
            </h6>
            {songs.map((song, index) => (
                <SongsSample
                    key={index}
                    ref={(el) => (audioRefs.current[index] = el)}
                    audioSrc={song.src}
                    audioRefs={audioRefs}
                    index={index}
                />
            ))}
        </div>
    );
}
