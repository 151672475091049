import styles from "../css/Dashboard.module.css";
import arrow from "../images/Group 15.png";

export default function MyDashboard() {
    return (
        <section className={`${styles.sectionBanner} section-banner py-[70px]`}>
            <div className="container m-auto p-5">
                <div className="banner-content new-content  w-full mr-auto">
                    <span className="text-white font-normal text-[25px] leading-[33px]">
                        Welcome Back, Sofia
                    </span>
                    <h1 className="font-bold mt-[40px]">Job Alert:</h1>
                    <h2 className="font-bold text-[52px] my-[20px] leading-[60px] text-white max-w-[570px] w-full">
                        Earn $300-$600{" "}
                        <span className="font-medium">
                            for a 1-hour project!{" "}
                        </span>
                    </h2>
                    <p className="py-[25px] pt-0 text-lg lg:text-left lg:text-base text-[#EDEDED]">
                        Now hiring native speakers in 25 languages.
                    </p>

                    <button
                        type="button"
                        className="text-white flex gap-[15px] mr-auto items-center bg-[#01BCAC]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                    >
                        Audition Now
                        <img src={arrow} alt="icon" className="w-[30px]" />
                    </button>
                </div>
            </div>
        </section>
    );
}
