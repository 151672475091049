import { Breadcrumbs } from "@material-tailwind/react";
 
export function Breadcrumb() {
  return (
    <Breadcrumbs fullWidth>
      <a href="#" className="font-bold">
      Voice Actors
      </a>
      <a href="#" className="font-normal">
      Browse Talent
      </a>
       
    </Breadcrumbs>
  );
}