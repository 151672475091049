import arrow from "../images/Group 15.png";
import MusicBoard from "./MusicBoard";

export default function Editor() {
  return (
    <>
      <section className="relative editor-banner py-[30px] md:py-[60px]">
        <div className="container relative m-auto p-5 pt-0 z-10">
          <h2 className="text-[#0E1629] text-[45px] leading-[55px] m-auto w-full font-bold text-center">
            Browse Top Talent or Editor’s Picks
          </h2>
          <p className="pt-3 text-center text-lg max-w-[690px] m-auto w-full lg:text-base text-[#1B253D]">
            Hire the perfect talent and work closely with them to bring your job
            to life.Or, skip the back-and-forth by purchasing packages with
            upfront costs and timelines.
          </p>

          <div className="row pt-[40px]">
            <h4 className="text-[#0E1629] pl-[30px] font-bold text-[36px] leading-[43px] relative">
              Top Talent
            </h4>
            <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[25px] pt-[25px]">
              <MusicBoard />
            </div>

            <div className="text-center pt-[50px]">
              <button
                type="button"
                class="text-white flex gap-[15px] items-center m-auto bg-[#0E1629]  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
              >
                See More Talent
                <img src={arrow} alt="icon" className="w-[30px]" />
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
