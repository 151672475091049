import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'

const items = [
  { id: 1, title: 'Back End Developer', department: 'Engineering', type: 'Full-time', location: 'Remote' },
  { id: 2, title: 'Front End Developer', department: 'Engineering', type: 'Full-time', location: 'Remote' },
  { id: 3, title: 'User Interface Designer', department: 'Design', type: 'Full-time', location: 'Remote' },
]

export default function Pagination() {
  return (
    <div className="mt-[40px]">
      <div className="flex flex-1 justify-between sm:hidden">
        <a
          href="#"
          className="relative inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Previous
        </a>
        <a
          href="#"
          className="relative ml-3 inline-flex items-center rounded-md border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700 hover:bg-gray-50"
        >
          Next
        </a>
      </div>
      <div className="hidden sm:flex sm:flex-1 sm:items-center justify-center">
        <div>
          <nav className="inline-flex gap-[15px] items-center" aria-label="Pagination">
            <a
              href="#"
              className="relative inline-flex items-center rounded-l-md px-2 py-2 bg-[#fff] text-[#01BCAC]"
            >
              <span className="sr-only">Previous</span>
              <ChevronLeftIcon className="w-10" aria-hidden="true" />
            </a>
            {/* Current: "z-10 bg-indigo-600 text-white focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600", Default: "text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 focus:outline-offset-0" */}
            <a
              href="#"
              aria-current="page"
              className="relative z-10 inline-flex items-center bg-[#01BCAC] px-4 py-2 text-sm font-semibold text-white"
            >
              1
            </a>
            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 bg-[#fff] text-[#01BCAC] text-sm font-bold"
            >
              2
            </a>
            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 bg-[#fff] text-[#01BCAC] text-sm font-bold"
            >
              3
            </a>

            <a
              href="#"
              className="relative inline-flex items-center px-4 py-2 bg-[#fff] text-[#01BCAC] text-sm font-bold"
            >
              4
            </a>
            <span className="relative inline-flex items-center text-[#01BCAC] text-[22px] font-bold">
              ...
            </span>
          
            <a
              href="#"
              className="relative bg-[#fff] text-[#01BCAC] inline-flex items-center border-0 rounded-r-md px-2 py-2"
            >
              <span className="sr-only">Next</span>
              <ChevronRightIcon className="w-10" aria-hidden="true" />
            </a>
          </nav>
        </div>
      </div>
    </div>
  )
}
