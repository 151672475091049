import image from "../images/profile.png";
import Checkboxes from "./Checkboxes";
import Languages from "./Languages";
import ReviewBlock from "./ReviewBlock";

export default function SingleProfile() {
    return (
        <div className="profile rounded-[15px] col-span-12 lg:col-span-8">
            <div className="image-block">
                <div className="profile-identity flex items-start mb-[30px]">
                    <div className="flex items-center flex-wrap gap-[15px]">
                        <img
                            src={image}
                            alt="img"
                            className="w-full max-w-[90px] lg:max-w-[130px] p-[6px]"
                        />

                        <div className="">
                            <h5 className="text-[25px] leading-[40px] font-bold">
                                Lisa Mathew
                            </h5>
                            <p className="text-[19px] leading-[23px]">
                                West Orange, New Jersey, US
                            </p>
                        </div>
                    </div>

                    <div className="views ml-auto">314 Views</div>
                </div>

                <div className="profile-des">
                    <h6 className="font-semibold text-[25px] leading-[30px]">
                        About Me
                    </h6>
                    <p className="text-[17px] leading-[25px] text-[#0E1629] font-normal mb-[30px]">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since the 1500s,
                        when an unknown printer took a galley of type and
                        scrambled it to make a type specimen book. It has
                        survived not only five centuries, but also the leap into
                        electronic typesetting, remaining essentially unchanged.
                    </p>
                    <h6 className="font-semibold text-[25px] leading-[30px]">
                        Voice Language
                    </h6>

                    <Languages />

                    <h6 className="font-semibold text-[25px] leading-[30px]">
                        Voice Category
                    </h6>

                    <Checkboxes />

                    <h6 className="font-semibold text-[25px] mb-[30px] leading-[30px]">
                        Reviews
                    </h6>
                    <ReviewBlock />
                    <ReviewBlock />
                    <ReviewBlock />
                    <a
                        href="#"
                        className="text-[#01BCAC] text-center block mt-[50px] mx-auto text-[19px] m-auto leading-[23px] font-bold"
                    >
                        Show More
                    </a>
                </div>
            </div>
        </div>
    );
}
