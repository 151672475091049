import { IoMdInformationCircle } from "react-icons/io";
import arrow from "../images/Group 15.png";

export default function JobReview() {
    return (
        <section className="py-[60px]">
            <div className="container p-5 m-auto">
                <div className="row flex items-center justify-between max-w-[1000px] w-full m-auto">
                    <div className="inner-col text-center">
                        <div className="alert-box flex items-center gap-[5px]">
                            <h4 className="text-[40px] leading-[50px] text-[#0E1629] font-medium">Review Jobs</h4>
                            <IoMdInformationCircle className="text-[25px]" />
                        </div>
                        <p className="text-[#5D5D5D] font-normal my-[15px] text-[19px] leading-[22px]">
                            Voice Over
                        </p>

                        <button
                            type="button"
                            className="text-[#01BCAC] mt-[25px] border border-solid border-[#01BCAC] tab-btn flex gap-[15px] items-center m-auto bg-transparent  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                        >
                            100 Public Invites
                            <img src={arrow} alt="icon" className="w-[30px]" />
                        </button>
                    </div>

                    <div className="inner-col text-center">
                        <div className="alert-box flex items-center gap-[5px]">
                            <h4 className="text-[40px] leading-[50px] text-[#0E1629] font-medium">No Response Shortlists Yet</h4>
                        </div>
                        <p className="text-[#5D5D5D] my-[15px] font-normal text-[19px] leading-[22px]">
                            How to improve getting shortlisted?
                        </p>

                        <button
                            type="button"
                            className="text-[#01BCAC] mt-[25px] border border-solid border-[#01BCAC] tab-btn flex gap-[15px] items-center m-auto bg-transparent  focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-lg px-5 py-2.5  focus:outline-none"
                        >
                            Learn Tips on Responding to Jobs
                            <img src={arrow} alt="icon" className="w-[30px]" />
                        </button>
                    </div>
                </div>
            </div>
        </section>
    );
}
