import imagetwo from "../images/reviews.svg";
import imagethree from "../images/reviews.svg";

export default function ReviewBlock() {
    return (
        <>
            <div className="reviews-block flex justify-between sm:block">
                <div className="rating-block flex flex-wrap gap-[25px] gap-y-[10px]">
                    <h6 className="text-[19px] leading-[23px] text-[#0E1629] font-semibold">
                        Pirates Baseball 
                    </h6>
                    <div className="star flex gap-[5px]">
                        <img
                            src={imagetwo}
                            alt="stars"
                            className="max-w-[30px] w-full"
                        />
                        <img
                            src={imagetwo}
                            alt="stars"
                            className="max-w-[30px] w-full"
                        />
                        <img
                            src={imagetwo}
                            alt="stars"
                            className="max-w-[30px] w-full"
                        />
                        <img
                            src={imagetwo}
                            alt="stars"
                            className="max-w-[30px] w-full"
                        />
                        <img
                            src={imagethree}
                            alt="stars"
                            className="max-w-[30px] w-full"
                        />
                    </div>

                    <p className="text-[#0E1629] text-[16px] leading-[23px] font-normal">
                        Lorem Ipsum is simply dummy text of the printing and
                        typesetting industry. Lorem Ipsum has been the
                        industry's standard dummy text ever since.
                    </p>
                </div>

                <p className="text-[#0E1629] text-[16px] leading-[23px] font-normal text-right">
                    Feb 4, 2024
                </p>
            </div>
        </>
    );
}
