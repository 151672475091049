import Checkboxes from "../Components/Checkboxes";


export default function Categories() {
  return (
    <section className="md:py-[60px] py-[30px] bg-[#0E1629]">
      <div className="container m-auto px-5">
        <div className="browse-content text-center">
          <h2 className="text-white text-[45px] leading-[55px] m-auto w-full font-bold">
            Browse Other Voice Over Categories
          </h2>
          <Checkboxes />
        </div>
      </div>
    </section>
  );
}
