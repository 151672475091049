import Header from "../Components/header";
import Slider from "../Components/Slider";
import Banner from "../Components/Banner";
import Brands from "../Components/Brands";
import Cards from "../Components/Cards";
import Voices from "../Components/Voices";
import Services from "../Components/Services";
import Editor from "../Components/Editor";
import Edited from "../Components/Edited";
import Slick from "../Components/Slick";
import Footer from "../Components/Footer";
import Crousel from "../Components/Crousel";
import Tools from "../Components/Tools";
import Work from "../Components/Work";
import FindWork from "../Components/FindWork";

function Home() {
    return (
        <div className="Home">
            <Header />
            <Slider />
            <Banner />
            <Brands />
            <Cards />
            <Voices />
            <Services />
            <Editor />
            <Edited />
            <Slick />
            <Crousel />
            <Tools />
            <FindWork />
             <Work />
           <Footer />          
        </div>
    );
}

export default Home;
